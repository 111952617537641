import React from "react";
import styles from "./ResultsCards.module.scss";
import Button from "../Button/Button";
import { truncate } from "../../lib/utils";

const ResultsCards = (props) => {
  const { content, index, appNavHandler, type, id, featuredImage } = props;
  const shortenedExcerpt = truncate(content.excerpt.rendered, 169);

  return (
    <div
      className={styles.imageTitleContainer}
      key={`content-container-${index}`}
    >
      <div>
        <img
          className={styles.featuredImage}
          src={featuredImage}
          alt="post-featured"
        />
      </div>
      <div className={styles.titleExcerptContainer}>
        <h3 className={styles.contentTitle}>{content.title.rendered}</h3>
        <div>
          <div
            className={styles.excerptContainer}
            dangerouslySetInnerHTML={{ __html: shortenedExcerpt }}
          />
          <Button
            text="More"
            actionHandler={() => appNavHandler(type, id, featuredImage)}
            link={null}
          />
        </div>
      </div>
    </div>
  );
};

export default ResultsCards;

import React from "react";
import { Tab } from "@material-ui/core";

export const LinkTab = (props: any) => {
  return (
    <Tab
      classes={{ ...props.classes }}
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
};

import React from "react";
import styles from "./ErrorMessage.module.scss";

const ErrorMessage = (props) => {
  const { error } = props;

  return (
    <div className={styles.errorContainer}>
      <p className={styles.errorMessage}>{error}</p>
    </div>
  );
};

export default ErrorMessage;

import React, { useContext } from "react";
import { Button } from "@material-ui/core";
import styles from "./PostCard.module.scss";
import AppContext from "../Context/AppContext";
import MoreButton from "../../components/MoreBtn/MoreBtn";

const PostCard = (props) => {
  const {
    altImage,
    sourceImg,
    postId,
    featured,
    postTitle,
    postExcerpt,
    _index,
  } = props;

  const { appNavHandler } = useContext(AppContext);

  return (
    <>
      <div className={styles.post_entry}>
        <div className={styles.featuredContainer}>
          <img
            alt={altImage}
            className={styles.featured_image}
            src={sourceImg}
          />
        </div>
        <h3>
          <Button
            component="a"
            onClick={() => appNavHandler("post", postId, featured)}
            className={styles.titleButton}
          >
            <p className={styles.postTitle}>{postTitle}</p>
          </Button>
        </h3>
        <div
          className={styles.excerptContainer}
          dangerouslySetInnerHTML={{
            __html: postExcerpt,
          }}
        />
        <MoreButton
          actionHandler={() => appNavHandler("post", postId, featured)}
        />
      </div>
      {_index + 1 > 1 && (_index + 1) % 4 === 0 && (
        <div className={styles.clearfix} />
      )}
    </>
  );
};

export default PostCard;

import React, { useState, useEffect, useContext, useRef } from "react";
import { FadeLoader } from "react-spinners";
import axios from "axios";
import _ from "lodash";
import SearchBox from "../../components/SearchBox/SearchBox";
import styles from "./Home.module.scss";
import InfoPacks from "../../components/InfoPacks/InfoPacks";
import Library from "../../components/Library/Library";
import PremiumResourcesSection from "../../sections/PremiumResourcesSection/PremiumResourcesSection";
import {
  getThreeObjItems as getThreeObjectItems,
  getFourOjbItems,
  getSingleObjProp as getSingleObjectProperty,
} from "../../lib/utils";
import ResearchProduct from "../../sections/ResearchProduct/ResearchProduct";
import {
  serviceConfig,
  premiumConfig,
  researchConfig,
  infoPackPagesConfig,
} from "../../lib/config";
import OurServices from "../../sections/OurServices/OurServices";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import KnowledgeCenterContext from "../../components/Context/KnowledgeCenterContext";
import HelpDesk from "../../sections/HelpDesk/HelpDesk";
import Footer from "../../sections/Footer/Footer";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import AppContext from "../../components/Context/AppContext";

const Home = () => {
  const [infoPackPages, setInfoPackPages] = useState([]);
  const [isInfoPagesLoaded, setIsInfoPagesLoaded] = useState(false);
  const [libElement, setLibElement] = useState({ top: 0 });
  const wpEndPoint = process.env.REACT_APP_WP_API_URL;

  const {
    knowledgeCenterData,
    tabValue,
    setTabValue,
    scrollToLib,
    scrollHome,
    setScrollToLib,
    setScrollHome,
  } = useContext(AppContext);

  const knowledgeCenterInfoPacks = knowledgeCenterData.isLoading
    ? []
    : getSingleObjectProperty(knowledgeCenterData, infoPackPagesConfig);

  const libraryReference = useRef(null);

  const setLibHandler = () => {
    const node = libraryReference.current;
    setLibElement(node ? node?.getBoundingClientRect() : {});
  };

  useEffect(() => {
    setLibHandler();

    const fetchInfoPackPages = async () => {
      let pageContent;
      let getInfoPackPages;

      if (
        knowledgeCenterInfoPacks.length > 0 &&
        !_.isEmpty(knowledgeCenterInfoPacks[0])
      ) {
        getInfoPackPages = await Promise.all(
          knowledgeCenterInfoPacks.map(async (item, index) => {
            const pageId =
              item[`info_pack_pages_${index}_info_pack_chosen_pages`];

            try {
              pageContent = await axios.get(
                `${wpEndPoint}wp/v2/pages/${pageId}`
              );
            } catch (error) {
              throw error;
            }

            return pageContent.data;
          })
        );
      }

      setInfoPackPages(getInfoPackPages);
      setIsInfoPagesLoaded(true);
    };

    fetchInfoPackPages();

    if (scrollToLib) {
      scrollToLibrary(tabValue);

      setTimeout(() => {
        setScrollToLib(false);
      }, 1000);
    }

    if (scrollHome) {
      scrollTop();

      setTimeout(() => {
        setScrollHome(false);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    knowledgeCenterInfoPacks.length,
    scrollToLib,
    scrollHome,
    knowledgeCenterInfoPacks.length,
  ]);

  const servicesIconDesc = knowledgeCenterData.isLoading
    ? []
    : getThreeObjectItems(knowledgeCenterData, serviceConfig);

  const premiumResources = knowledgeCenterData.isLoading
    ? []
    : getFourOjbItems(knowledgeCenterData, premiumConfig);

  const researchProducts = getFourOjbItems(knowledgeCenterData, researchConfig);

  const scrollToLibrary = (tab: number) => {
    setTabValue(tab);

    window.scrollTo({
      top: libElement.top,
      behavior: "smooth",
    });
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div>
      {knowledgeCenterData.isLoading ? (
        <div className={styles.loaderContainer}>
          <FadeLoader
            height={20}
            width={5}
            radius={10}
            margin={10}
            color="#999"
            loading={knowledgeCenterData.isLoading}
          />
        </div>
      ) : knowledgeCenterData.isError ? (
        <ErrorMessage error="Something Went Wrong..." />
      ) : (
        <KnowledgeCenterContext.Provider
          value={{
            knowledgeCenter: knowledgeCenterData,
            premiumItems: premiumResources,
            serviceItems: servicesIconDesc,
            researchItems: researchProducts,
            isLoading: knowledgeCenterData.isLoading,
            isInfoPagesLoaded,
            infoPackPages,
            scrollToLibrary,
            tabValue,
            setTabValue,
            scrollTop,
          }}
        >
          <h4 className={styles.knowledgeHeader}>KNOWLEDGE CENTER</h4>
          <SectionHeader header="title" subHeader={null} big />
          <SearchBox />
          <InfoPacks />
          <div ref={libraryReference}>
            <Library />
          </div>
          <PremiumResourcesSection />
          <ResearchProduct />
          <OurServices />
          <HelpDesk />
          <Footer inBlogs={false} />
        </KnowledgeCenterContext.Provider>
      )}
    </div>
  );
};

export default Home;

import { useQuery } from "react-query";
import axios from "axios";

const wpEndPoint = process.env.REACT_APP_WP_BASE_URI;

const getSingleInfoPackMeta = async (slug) => {
  return await axios.get(`${wpEndPoint}${slug}/?headless=1`);
};

export const useInfoPackMeta = (slug, slugExist) =>
  useQuery(
    `singleInfoPack-${slug}`,
    async () => {
      const response = await getSingleInfoPackMeta(slug);

      return response.data;
    },
    {
      staleTime: 600_000,
      retry: 1,
      enabled: slugExist,
    }
  );

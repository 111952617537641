/* eslint-disable no-restricted-globals */
import React, { useEffect, useState, useCallback } from "react";
import { ClipLoader } from "react-spinners";
import Pagination from "@material-ui/lab/Pagination";
import {
  getRelatedPosts,
  getRelatedPostsCount,
} from "../../services/get-relatedposts";
import RelatedPostCard from "../../components/RelatedPostCard/RelatedPostCard";
import styles from "./RelatedPosts.module.scss";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import { truncate } from "../../lib/utils";
import Divider from "../Divider/Divider";

const RelatedPosts = (props: any) => {
  const FEATURED_IMAGE_KEY = 0;
  const [data, setData] = useState<any>([]);
  const [pageCurrent, setPageCurrent] = useState<any>(1);
  const [allRP, setAllRP] = useState<any>();

  const refetch = useCallback(
    async (_pageCurrent) => {
      const _allRP = await getRelatedPostsCount(
        props.id,
        props.categories.join(",")
      );

      setAllRP(_allRP);

      const categoryData = await getRelatedPosts(
        _pageCurrent,
        props.categories.join(","),
        props.id
      );

      setData(categoryData);
    },
    [props.categories, props.id]
  );

  useEffect(() => {
    props.categories && refetch(pageCurrent);
  }, [props, pageCurrent, refetch]);

  return (
    <>
      <Divider />
      <h4 className={styles.sectionHeader}>Related Posts</h4>
      {!data ? (
        <ClipLoader loading={data.isLoading} size={150} />
      ) : data?.isError ? (
        <ErrorMessage error="Something Went Wrong..." />
      ) : (
        <div>
          {data.data?.length > 0 &&
            data.data?.map((d: any, _index: any) => {
              const altImage =
                d._embedded["wp:featuredmedia"][FEATURED_IMAGE_KEY]?.alt_text;

              const sourceImg =
                d._embedded["wp:featuredmedia"][FEATURED_IMAGE_KEY]
                  ?.media_details?.sizes?.medium?.source_url;

              const postId = d.id;

              const featured =
                d._embedded["wp:featuredmedia"][FEATURED_IMAGE_KEY]?.source_url;

              const postTitle = d?.title?.rendered;
              const postExcerpt = truncate(d?.excerpt.rendered, 169);

              return (
                <RelatedPostCard
                  postExcerpt={postExcerpt}
                  postTitle={postTitle}
                  altImage={altImage}
                  sourceImg={sourceImg}
                  postId={postId}
                  featured={featured}
                />
              );
            })}
          <div className={styles.clearfix} />
          {allRP?.data?.length > 0 && (
            <Pagination
              count={Math.ceil(allRP?.data?.length / 4)}
              page={pageCurrent}
              onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                setPageCurrent(value);
                refetch(value);
              }}
            />
          )}
        </div>
      )}
    </>
  );
};

export default RelatedPosts;

import { useQuery } from "react-query";
import axios from "axios";

const wpEndPoint = process.env.REACT_APP_PODCAST_URI;

const getCategory = async (slug) => {
  return await axios.get(`${wpEndPoint}wp-json/wp/v2/categories?slug=${slug}`);
};

export const useCategory = (slug) =>
  useQuery(
    `category-${slug}`,
    async () => {
      const response = await getCategory(slug);

      return response.data;
    },
    {
      staleTime: 600_000,
      retry: 1,
    }
  );

import React, { useContext } from "react";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import Button from "../../components/Button/Button";
import KnowledgeCenterContext from "../../components/Context/KnowledgeCenterContext";
import { getDeepObjMetas as getDeepObjectMetas } from "../../lib/utils";
import styles from "./HelpDesk.module.scss";
import SectionWrapper from "../../components/SectionWrapper/SectionWrapper";

const HelpDesk = () => {
  const { knowledgeCenter } = useContext(KnowledgeCenterContext);
  const link = getDeepObjectMetas(knowledgeCenter, "helpdesk_more_link");

  return (
    <SectionWrapper>
      <SectionHeader header="helpdesk_title" subHeader="helpdesk_description" />
      <div className={styles.buttonWrapper}>
        <Button text="More >" link={link} />
      </div>
    </SectionWrapper>
  );
};

export default HelpDesk;

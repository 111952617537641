import React from "react";
import styles from "./Divider.module.scss";

const Divider = (props) => {
  return (
    <div>
      <hr className={styles.divider} />
    </div>
  );
};

export default Divider;

import React, { useContext } from "react";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import styles from "./Footer.module.scss";
import {
  getDeepObjMetas as getDeepObjectMetas,
  getThreeObjItems as getThreeObjectItems,
} from "../../lib/utils";
import AppContext from "../../components/Context/AppContext";
import {
  knowledgeColumn,
  footerConfigPremium,
  footerConfigTutorial,
} from "../../lib/config";
import Divider from "../../components/Divider/Divider";

import KnowledgeCenterContext from "../../components/Context/KnowledgeCenterContext";

const Footer = (props) => {
  const { inBlogs } = props;
  const { knowledgeCenterData, appNavLibrary } = useContext(AppContext);
  const { scrollToLibrary } = useContext(KnowledgeCenterContext);

  const footerPremium = knowledgeCenterData.isLoading
    ? []
    : getThreeObjectItems(knowledgeCenterData, footerConfigPremium);

  const footerTutorial = knowledgeCenterData.isLoading
    ? []
    : getThreeObjectItems(knowledgeCenterData, footerConfigTutorial);

  const { appNavHandler, appNavHome } = useContext(AppContext);

  const columnPremiumTitle = getDeepObjectMetas(
    knowledgeCenterData,
    "footer_premium_resource_title"
  );

  const columnTutorialTitle = getDeepObjectMetas(
    knowledgeCenterData,
    "footer_tutorial_column_title"
  );

  return (
    <div>
      <Divider />
      <SectionHeader header="footer_title" subHeader={null} />
      <div className={styles.footerContainer}>
        <div className={styles.columnContainer}>
          <h1 className={styles.footerNavTitle}>Knowledge Center</h1>
          {knowledgeColumn.map((item) => {
            const { name, link } = item;
            let tabValue = 0;
            let content;

            switch (name) {
              case "Blogs":
                tabValue = 0;
                break;
              case "Videos":
                tabValue = 1;
                break;
              case "Podcasts":
                tabValue = 2;
                break;
              default:
                tabValue = 0;
            }

            const internal = (
              <p
                onClick={
                  inBlogs
                    ? () => appNavLibrary("home", tabValue, true, false)
                    : () => scrollToLibrary(tabValue)
                }
                className={styles.footerItem}
              >
                {name}
              </p>
            );

            const homePage = (
              <p
                onClick={() => appNavHome("home", true, false)}
                className={styles.footerItem}
              >
                {name}
              </p>
            );

            const external = (
              <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.footerItem}
              >
                {name}
              </a>
            );

            if (name === "Support") {
              content = external;
            } else if (name === "Home") {
              content = homePage;
            } else {
              content = internal;
            }

            return content;
          })}
        </div>
        <div className={styles.columnContainer}>
          <h1 className={styles.footerNavTitle}>{columnTutorialTitle}</h1>
          {footerTutorial.map((item, index) => {
            const name =
              item[`footer_tutorial_items_${index}_footer_item_name`];

            const id = item[`footer_tutorial_items_${index}_footer_item_post`];

            return (
              <p
                onClick={() => appNavHandler("post", id, "")}
                className={styles.footerItem}
              >
                {name}
              </p>
            );
          })}
        </div>
        <div className={styles.columnContainer}>
          <h1 className={styles.footerNavTitle}>{columnPremiumTitle}</h1>
          {footerPremium.map((item, index) => {
            const link =
              item[`footer_premium_items_${index}_footer_premium_link`];

            const name =
              item[`footer_premium_items_${index}_footer_premium_name`];

            return (
              <a href={link} rel="noopener noreferrer" target="_blank">
                <p className={styles.footerItem}>{name}</p>
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Footer;

import React from "react";
import styles from "./SectionWrapper.module.scss";

const SectionWrapper = (props) => {
  const { children, addedStyle } = props;

  return (
    <div className={styles.sectionWrapper} style={{ ...addedStyle }}>
      {children}
    </div>
  );
};

export default SectionWrapper;

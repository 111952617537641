import { useQuery } from "react-query";
import axios from "axios";

const wpEndPoint = process.env.REACT_APP_WP_API_URL;

const getFeatured = async (featureId) => {
  return await axios.get(`${wpEndPoint}wp/v2/media/${featureId}`);
};

export const useFeatured = (featureId, enabled) => {
  return useQuery(
    "featured",
    async () => {
      const response = await getFeatured(featureId);

      return response.data;
    },
    {
      staleTime: 600_000,
      retry: 1,
      enabled,
    }
  );
};

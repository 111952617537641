import { useQuery } from "react-query";
import axios from "axios";

const wpEndPoint = process.env.REACT_APP_WP_BASE_URI;

export const getKnowledgeCenter = async () => {
  return await axios.get(`${wpEndPoint}knowledge-center-final/?headless=1`);
};

export const useKnowledgeCenter = () =>
  useQuery(
    "knowledgeCenter",
    async () => {
      const response = await getKnowledgeCenter();

      return response.data;
    },
    {
      staleTime: 600_000,
      retry: 1,
    }
  );

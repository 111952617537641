import React, { useContext } from "react";
import AppContext from "../../components/Context/AppContext";
import { useSinglePost } from "../../services/get-singlePost";
import GoBackButton from "../../components/GoBackBtn/GoBackBtn";
import styles from "./Post.module.scss";
import RelatedPosts from "../../components/RelatedPosts/RelatedPosts";
import "./Posts.css";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import Footer from "../../sections/Footer/Footer";
import ReactPlayer from "react-player";

const Post = () => {
  const { contentId, appNavHandler } = useContext(AppContext);
  const response = useSinglePost(contentId);
  const { data, isLoading, isError } = response;
  const videoLink = data?.acf?.header_video ?? null;

  return (
    <div>
      <div>
        {isLoading ? (
          <h3>Loading...</h3>
        ) : isError ? (
          <ErrorMessage error="Something Went Wrong..." />
        ) : (
          <>
            <div className={styles.kc_single_post}>
              <GoBackButton action={() => appNavHandler("home", null, "")} />
              <h2 className={styles.pageTitle}>{data!.title!.rendered}</h2>
              {videoLink ? (
                <div className={styles.videoContainer}>
                  <ReactPlayer url={videoLink} controls />
                </div>
              ) : null}
              <div
                className="wpContentContainer"
                dangerouslySetInnerHTML={{
                  __html: data?.content?.rendered.replace(
                    /<a /g,
                    '<a target="_blank" '
                  ),
                }}
              />
            </div>
            <div className="related-container">
              <RelatedPosts id={data?.id} categories={data.categories || []} />
            </div>
          </>
        )}
      </div>
      <Footer inBlogs />
    </div>
  );
};

export default Post;

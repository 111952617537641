import React, { useContext } from "react";
import clsx from "clsx";
import styles from "./TitleCards.module.scss";
import AppContext from "../Context/AppContext";
import { useFeatured } from "../../services/get-featured";

const TitleCards = (props) => {
  const { appNavHandler } = useContext(AppContext);
  const { title, index, id, featuredImage, goto } = props;
  const isFeaturedMedia = !!(featuredImage !== null || featuredImage !== "");
  const featuredMedia = useFeatured(featuredImage, isFeaturedMedia);

  const firstCardStyles =
    index === 0
      ? clsx(styles.titleCardBox, styles.titleCardFirst)
      : styles.titleCardBox;

  return (
    <div
      className={firstCardStyles}
      onClick={() => appNavHandler(goto, id, featuredMedia.data.source_url)}
    >
      <div>
        <h4 className={styles.cardTitle}>{title}</h4>
        <p className={styles.discoverMore}>{`Discover More >>`}</p>
      </div>
    </div>
  );
};

export default TitleCards;

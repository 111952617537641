import { useQuery } from "react-query";
import axios from "axios";

const wpEndPoint = process.env.REACT_APP_WP_API_URL;

const getSinglePage = async (pageId) => {
  return await axios.get(`${wpEndPoint}wp/v2/pages/${pageId}`);
};

export const useSinglePage = (id) =>
  useQuery(
    `singlePage${id}`,
    async () => {
      const response = await getSinglePage(id);

      return response.data;
    },
    {
      staleTime: 600_000,
      retry: 1,
    }
  );

/* eslint-disable no-restricted-globals */
import React, { useEffect, useState, useContext } from "react";
import Pagination from "@material-ui/lab/Pagination";
import ClipLoader from "react-spinners/ClipLoader";
import Tabs from "@material-ui/core/Tabs";
import { useLibrary, fetchCategory } from "../../services/get-library";
import styles from "./Library.module.scss";
import { LinkTab } from "../LinkTab/LinkTab";
import { TabPanel } from "../TabPanel/TabPanel";
import PodcastTab from "../PodcastTab/PodcastTab";
import PostCard from "../../components/PostCard/PostCard";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import "./LibraryPagination.css";
import { truncate } from "../../lib/utils";
import KnowledgeCenterContext from "../Context/KnowledgeCenterContext";

const Library = () => {
  const { tabValue, setTabValue } = useContext(KnowledgeCenterContext);
  const [currentCategory, setCurrentCategory] = useState<any>("");
  const [pageCurrent, setPageCurrent] = useState<number>(1);
  const [currentData, setCurrentData] = useState<any>({});

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [defaultTabIndex, setDefaultTabIndex] = useState<number>(
    pageCurrent - 1
  );

  const FEATURED_IMAGE_KEY = 0;
  const PODCAST_TAB_KEY = 2;
  const library = useLibrary(true); // not dependent on rerendering

  // ================= Methods ========================

  const refetch = async (_currentcat, _pageCurrent) => {
    const categoryData = await fetchCategory(_pageCurrent, _currentcat);
    setCurrentData(categoryData);
  };

  const handleChange = async (event: any, newValue: any) => {
    setTabValue(newValue);
    const _currentcat = library.data.tabs[newValue].id;
    setCurrentCategory(_currentcat);
    setPageCurrent(1);
    refetch(_currentcat, 1);
  };

  useEffect(() => {
    const _currentcat = library.data?.tabs[defaultTabIndex].id;
    setCurrentCategory(_currentcat);
    refetch(_currentcat, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTabIndex, library.isLoading]);

  // ================= Render ========================

  return (
    <div className="library-container">
      <h4 className={styles.titleHeading}>{library.data?.title}</h4>
      {!library ? (
        <ClipLoader loading={library.isLoading} size={150} />
      ) : library?.isError ? (
        <ErrorMessage error="Something Went Wrong..." />
      ) : (
        <div>
          <div className={styles.tabs}>
            <Tabs
              classes={{
                indicator: styles.tabs_indicator,
              }}
              onChange={handleChange}
              value={tabValue}
            >
              {library.data?.tabs.length > 0 &&
                library.data?.tabs.map((index_: any, index: any) => (
                  <LinkTab
                    label={index_.title}
                    id={index}
                    classes={{
                      textColorSecondary: styles.tabs_selected,
                      selected: styles.tabs_selected,
                    }}
                  />
                ))}
            </Tabs>
          </div>
          {library.data?.tabs.length > 0 &&
            library.data?.tabs.map((index_: any, index: any) => {
              return (
                <TabPanel
                  value={tabValue}
                  index={index}
                  className={styles.tabPanelContainer}
                >
                  {index === PODCAST_TAB_KEY ? (
                    <div className={styles.podCastTabContainer}>
                      <PodcastTab />
                    </div>
                  ) : currentData?.data?.length > 0 ? (
                    currentData?.data?.map((data: any, _index: any) => {
                      const altImage =
                        data._embedded["wp:featuredmedia"][FEATURED_IMAGE_KEY]
                          ?.alt_text;

                      const sourceImg =
                        data._embedded["wp:featuredmedia"][FEATURED_IMAGE_KEY]
                          ?.media_details?.sizes?.medium?.source_url;

                      const postId = data.id;

                      const featured =
                        data._embedded["wp:featuredmedia"][FEATURED_IMAGE_KEY]
                          ?.source_url;

                      const postTitle = data?.title?.rendered;
                      const postExcerpt = truncate(data?.excerpt.rendered, 169);

                      return (
                        <PostCard
                          postExcerpt={postExcerpt}
                          postTitle={postTitle}
                          featured={featured}
                          altImage={altImage}
                          sourceImg={sourceImg}
                          postId={postId}
                          _index={_index}
                        />
                      );
                    })
                  ) : (
                    <p>Loading {index_.title}...</p>
                  )}
                  <div className={styles.clearfix} />
                  {index === PODCAST_TAB_KEY ? null : (
                    <Pagination
                      classes={{
                        ul: styles.paginationItem,
                      }}
                      count={Math.ceil(index_.count / 8)}
                      page={pageCurrent}
                      onChange={(
                        event: React.ChangeEvent<unknown>,
                        value: number
                      ) => {
                        setPageCurrent(value);
                        refetch(currentCategory, value);
                      }}
                    />
                  )}
                </TabPanel>
              );
            })}
        </div>
      )}
    </div>
  );
};

export default Library;

import React from "react";
import clsx from "clsx";
import arrow from "../../images/arrow.svg";
import styles from "./Pagination.module.scss";

const Pagination = (props) => {
  const {
    current,
    leftDisabled,
    rightDisabled,
    setCountHandler,
    pageRange,
    prevPage,
    nextPage,
  } = props;

  return (
    <div className={styles.paginationWrapper}>
      <button
        onClick={() => {
          prevPage();
          setCountHandler("prev");
        }}
        disabled={leftDisabled}
        className={styles.navButtons}
      >
        <img
          className={clsx(styles.paginationArrow, styles.leftArrow)}
          src={arrow}
          alt="previous-arrow"
        />
      </button>
      {pageRange.map((pageResult) => {
        if (pageResult === current)
          return <p className={styles.currentPageNumber}>{pageResult}</p>;

        return <p className={styles.pageNumber}>{pageResult}</p>;
      })}
      <button
        className={styles.navButtons}
        onClick={() => {
          nextPage();
          setCountHandler("next");
        }}
        disabled={rightDisabled}
      >
        <img
          className={clsx(styles.paginationArrow, styles.rightArrow)}
          src={arrow}
          alt="next-arrow"
        />
      </button>
    </div>
  );
};

export default Pagination;

exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._27_dcFEFfqvcBxNKvJV5CE {\n  clear: both;\n}\n\n._2aOLBjJjMasRdRl_YQ5ruQ {\n  font-family: \"Poppins\", \"sans-serif\", -apple-system, monospace !important;\n  font-style: normal;\n  font-weight: bold;\n  font-size: 28px !important;\n  margin-bottom: 10px;\n  margin-top: 80px;\n  color: #333333 !important;\n  text-transform: uppercase;\n}\n\n._243GAB71zC7Kzogl01HJzl * {\n  font-family: \"Poppins\", \"sans-serif\", -apple-system, monospace !important;\n  font-weight: 500 !important;\n  font-size: 16px !important;\n  color: #333333 !important;\n}\n._243GAB71zC7Kzogl01HJzl *:hover {\n  text-decoration: none;\n}\n._243GAB71zC7Kzogl01HJzl ._243GAB71zC7Kzogl01HJzl * {\n  font-size: medium;\n}\n._243GAB71zC7Kzogl01HJzl ._243GAB71zC7Kzogl01HJzl *:hover {\n  text-decoration: none;\n}\n._243GAB71zC7Kzogl01HJzl ._243GAB71zC7Kzogl01HJzl *:focus {\n  outline: none;\n}\n\n.PJRap3IFVc8QAiSLoamJ2 {\n  font-weight: 500 !important;\n}\n.PJRap3IFVc8QAiSLoamJ2 span {\n  color: var(--portal-page-primary-text) !important;\n  font-weight: 500 !important;\n}\n\n._2i4igwLjgzZl9ABPplbHK2 {\n  background-color: var(--portal-page-primary-text) !important;\n}\n\n._2qaICZ5FU6qUJ8AGtqDwK_ {\n  display: flex;\n  margin-top: 2em;\n  width: 100%;\n}\n._2qaICZ5FU6qUJ8AGtqDwK_ > div {\n  width: 100%;\n  background-color: #f8f8f8 !important;\n  padding: 0.5em;\n}\n\n._1dau7WTSipyLwiYbfsD4EZ {\n  width: 100%;\n}\n\n._2Z-lZl8ii7RUCKN6sRwDNl button {\n  font-size: 12px;\n  background: none !important;\n}\n\n._3mqDjtmds_pyqJG1OdyV3F {\n  background: red;\n}\n\n@media (min-width: 768px) {\n  ._243GAB71zC7Kzogl01HJzl * {\n    font-size: 26px !important;\n    color: #333333 !important;\n  }\n}", ""]);

// Exports
exports.locals = {
	"clearfix": "_27_dcFEFfqvcBxNKvJV5CE",
	"titleHeading": "_2aOLBjJjMasRdRl_YQ5ruQ",
	"tabs": "_243GAB71zC7Kzogl01HJzl",
	"tabs_selected": "PJRap3IFVc8QAiSLoamJ2",
	"tabs_indicator": "_2i4igwLjgzZl9ABPplbHK2",
	"tabPanelContainer": "_2qaICZ5FU6qUJ8AGtqDwK_",
	"podCastTabContainer": "_1dau7WTSipyLwiYbfsD4EZ",
	"paginationItem": "_2Z-lZl8ii7RUCKN6sRwDNl",
	"paginationItemSelected": "_3mqDjtmds_pyqJG1OdyV3F"
};
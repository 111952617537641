exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._10boAPTjkzlxQYQ-dd_xa3 {\n  text-align: center;\n  width: calc(25% - 20px);\n  float: left;\n  display: flex;\n  flex-direction: column;\n}\n\n.tDKgxCGZHlS-5ioKo7V3H {\n  font-family: \"Poppins\", \"sans-serif\", -apple-system, monospace !important;\n  font-style: normal;\n  font-weight: bold;\n  font-size: 18px;\n  color: #333333 !important;\n  text-align: left;\n}\n\n._3VUWUU_xkFOc7VczDD8QY3 p {\n  font-family: \"Poppins\", \"sans-serif\", -apple-system, monospace !important;\n  color: #333333 !important;\n  font-size: 12px !important;\n  text-align: left;\n}\n\n._3VKF65U_lyP1d7uTkcpKji {\n  color: var(--portal-page-primary-text) !important;\n  font-family: \"Poppins\", \"sans-serif\", -apple-system, monospace !important;\n  font-size: 12px !important;\n  text-align: left;\n  cursor: pointer;\n}\n\n._1mR6bVTquDtN7-Ex7OL1Cr {\n  background-color: #888;\n  content: \"No image available\";\n  font-size: 30px;\n  color: #fff;\n  display: block;\n  width: 100%;\n}\n\n.bu_zfy20mHbddNtnRJ-AA {\n  clear: both;\n}\n\n._1nArlehpNmNc8c_EIRfWqZ {\n  text-transform: uppercase;\n  color: #333333 !important;\n  font-size: 28px !important;\n  font-family: \"Poppins\", \"sans-serif\", -apple-system, monospace !important;\n  margin-top: 60px;\n  margin-left: 10px;\n}", ""]);

// Exports
exports.locals = {
	"post_entry": "_10boAPTjkzlxQYQ-dd_xa3",
	"postTitle": "tDKgxCGZHlS-5ioKo7V3H",
	"excerptContainer": "_3VUWUU_xkFOc7VczDD8QY3",
	"moreButton": "_3VKF65U_lyP1d7uTkcpKji",
	"featured_image": "_1mR6bVTquDtN7-Ex7OL1Cr",
	"clearfix": "bu_zfy20mHbddNtnRJ-AA",
	"sectionHeader": "_1nArlehpNmNc8c_EIRfWqZ"
};
import React, { useState, useContext } from "react";
import { FadeLoader } from "react-spinners";
import TitleCards from "../../components/TitleCard/TitleCards";
import styles from "./InfoPacks.module.scss";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import SectionWrapper from "../../components/SectionWrapper/SectionWrapper";
import Pagination from "../Pagination/Pagination";
import usePage from "../Hooks/usePage";
import KnowledgeCenterContext from "../Context/KnowledgeCenterContext";

const InfoPacks = () => {
  const { isInfoPagesLoaded, infoPackPages } = useContext(
    KnowledgeCenterContext
  );

  const [count, setCount] = useState(0);
  const numberOfPages = infoPackPages ? Math.ceil(infoPackPages.length / 4) : 0;
  const pagination = usePage(0, numberOfPages, 4, 1000);

  const { nextPage, prevPage, pageRange, subIndexStart, subIndexEnd } =
    pagination;

  const pagesArray = [];
  const subPagedData = (infoPackPages || []).slice(subIndexStart, subIndexEnd);

  for (let index = 0; index < numberOfPages; index++) {
    pagesArray.push(index);
  }

  const setCountHandler = (type) => {
    if (type === "prev") {
      setCount((count) => {
        return count === pagesArray[0] ? count : count - 1;
      });
    } else if (type === "next") {
      setCount((count) => {
        return count === pagesArray.length - 1 ? count : count + 1;
      });
    }
  };

  const infoPacks = subPagedData?.map((page: any, index) => {
    return (
      <TitleCards
        index={index}
        title={page.title.rendered}
        key={`title-card-${index}`}
        id={page.id}
        featuredImage={page.featured_media}
        goto="infopack"
      />
    );
  });

  const leftDisabled = count === pagesArray[0];
  const rightDisabled = count === pagesArray[pagesArray.length - 1];

  return (
    <SectionWrapper addedStyle={addedStyle}>
      <SectionHeader header="feature_section_title" subHeader={null} />
      <div>
        {!isInfoPagesLoaded ? (
          <div className={styles.loaderContainer}>
            <FadeLoader
              height={20}
              width={5}
              radius={10}
              margin={10}
              color="#999"
              loading={!isInfoPagesLoaded}
            />
          </div>
        ) : (
          <div>
            <div className={styles.titleCardsWrapper}>{infoPacks}</div>
            {numberOfPages > 1 && (
              <Pagination
                prevPage={prevPage}
                nextPage={nextPage}
                pageRange={pageRange}
                leftDisabled={leftDisabled}
                rightDisabled={rightDisabled}
                current={count + 1}
                setCountHandler={setCountHandler}
              />
            )}
          </div>
        )}
      </div>
    </SectionWrapper>
  );
};

const addedStyle = {
  marginTop: "50px",
};

export default InfoPacks;

import React from "react";
import styles from "./Icon.module.scss";

const Icon = (props) => {
  const { source, rotate, iconStyle } = props;
  const iconStyleOption = rotate ? styles.iconRotate : styles.icon;

  return (
    <div className={styles.iconContainer}>
      <img
        className={iconStyleOption}
        style={{ ...iconStyle }}
        src={source}
        alt="arrow"
      />
    </div>
  );
};

export default Icon;

import React, { useContext } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { useSinglePage } from "../../services/get-singlePage";
import AppContext from "../../components/Context/AppContext";
import styles from "./Page.module.scss";
import GoBackButton from "../../components/GoBackBtn/GoBackBtn";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";

const Page = () => {
  const { contentId, appNavHandler, featuredMedia } = useContext(AppContext);
  const response = useSinglePage(contentId);
  const { data, isLoading, isError } = response;

  return (
    <div>
      <div>
        {isLoading ? (
          <h3>Loading...</h3>
        ) : isError ? (
          <ErrorMessage error="Something Went Wrong..." />
        ) : (
          <div>
            <GoBackButton action={() => appNavHandler("home", null, "")} />
            <h2 className={styles.pageTitle}>{data!.title!.rendered}</h2>
            {featuredMedia === "" ? (
              <div className={styles.loaderContainer}>
                <ClipLoader loading={featuredMedia === ""} size={150} />
              </div>
            ) : (
              <div className={styles.imageContainer}>
                <img
                  src={featuredMedia}
                  alt="page-featured-media"
                  className={styles.featuredImageStyle}
                />
              </div>
            )}
            <div
              className={styles.wpContentContainer}
              dangerouslySetInnerHTML={{ __html: data?.content?.rendered }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Page;

import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import styles from "./OurServices.module.scss";
import IconSlot from "../../components/IconSlot/IconSlot";
import Button from "../../components/Button/Button";
import KnowledgeCenterContext from "../../components/Context/KnowledgeCenterContext";
import { getDeepObjMetas as getDeepObjectMetas } from "../../lib/utils";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import SectionWrapper from "../../components/SectionWrapper/SectionWrapper";
import Loader from "../../components/Loader/Loader";

const OurServices = () => {
  const { serviceItems, isLoading, knowledgeCenter } = useContext(
    KnowledgeCenterContext
  );

  const link = getDeepObjectMetas(
    knowledgeCenter,
    "our_professional_service_more_link"
  );

  const [imageSources, setImageSources] = useState([]);
  const [isLoadingImages, setIsLoadingImages] = useState(false);
  const wpEndPoint = process.env.REACT_APP_WP_API_URL;

  useEffect(() => {
    const getImageSources = async () => {
      const imageSourceArray = await Promise.all(
        serviceItems.map(async (item, index) => {
          const imageId = item[`our_professional_service__${index}_icon`];

          const getImageUrl = await axios.get(
            `${wpEndPoint}wp/v2/media/${Number(imageId)}`
          );

          return getImageUrl.data.media_details.sizes.full.source_url;
        })
      );

      setImageSources(imageSourceArray);
    };

    getImageSources();

    if (isLoading && imageSources.length === 0) {
      setIsLoadingImages(true);
    } else {
      setIsLoadingImages(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SectionWrapper>
      <SectionHeader
        header="our_professional_service_title_main"
        subHeader="our_professional_service_description"
      />
      <div className={styles.iconCompWrapper}>
        {isLoadingImages ? (
          <Loader isLoading={isLoadingImages} />
        ) : (
          serviceItems.map((value, index) => {
            const title = value[`our_professional_service__${index}_title`];
            const link = value[`our_professional_service__${index}_link`];

            return (
              <IconSlot
                key={`iconSlot-card-${index}`}
                title={title}
                imageSource={imageSources[index]}
                index={index}
                link={link}
              />
            );
          })
        )}
      </div>
      <div className={styles.buttonWrapper}>
        <Button text="More >" link={link} />
      </div>
    </SectionWrapper>
  );
};

export default OurServices;

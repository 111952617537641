import { createContext } from "react";

type ContextProps = {
  portalDiv: HTMLElement;
  appNavHandler: Function;
  contentId: string | number;
  featuredMedia: string;
  knowledgeCenterData: any;
  tabValue: number;
  setTabValue: any;
  appNavLibrary: any;
  scrollToLib: boolean;
  scrollHome: boolean;
  appNavHome: any;
  setScrollHome: any;
  setScrollToLib: any;
};

const AppContext = createContext<Partial<ContextProps>>({});

export default AppContext;

import React, { useContext } from "react";
import { Button } from "@material-ui/core";
import styles from "./RelatedPostCard.module.scss";
import AppContext from "../Context/AppContext";
import MoreButton from "../MoreBtn/MoreBtn";

const RelatedPostCard = (props) => {
  const {
    altImage,
    sourceImg,
    postTitle,
    postExcerpt,
    postId,
    _index,
    featured,
  } = props;

  const { appNavHandler } = useContext(AppContext);

  return (
    <>
      <div className={styles.post_entry}>
        <img alt={altImage} className={styles.featured_image} src={sourceImg} />
        <Button
          className={styles.postTitleButton}
          component="div"
          onClick={() => {
            appNavHandler("post", postId, featured);
          }}
        >
          <h3
            className={styles.postTitle}
            dangerouslySetInnerHTML={{ __html: postTitle }}
          />
        </Button>
        <div
          className={styles.excerptContainer}
          dangerouslySetInnerHTML={{
            __html: postExcerpt,
          }}
        />
        <div className={styles.moreBtnWrapper}>
          <MoreButton
            actionHandler={() => appNavHandler("post", postId, featured)}
          />
        </div>
      </div>
      {_index + 1 > 1 && (_index + 1) % 4 === 0 && (
        <div className={styles.clearfix} />
      )}
    </>
  );
};

export default RelatedPostCard;

import React from "react";
import styles from "./GoBackBtn.module.scss";

const GoBackButton = (props) => {
  const { action } = props;

  return (
    <div>
      <h3 className={styles.goBackBtn} onClick={action}>
        {`<--`} Back to
        <span className={styles.capitalized}> knowledge centre</span>
      </h3>
    </div>
  );
};

export default GoBackButton;

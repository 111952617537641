import React from "react";

const Button = (props) => {
  const { actionHandler, customStyle, text, link } = props;
  let content;

  content =
    link === "" || link === undefined || link === null ? (
      <div>
        <button
          style={{ ...styles.moreButton, ...customStyle }}
          onClick={actionHandler}
        >
          {text}
        </button>
      </div>
    ) : (
      <div>
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          style={{ ...styles.moreButton, ...customStyle, ...styles.anchor }}
          onClick={actionHandler}
        >
          {text}
        </a>
      </div>
    );

  return content;
};

const styles = {
  moreButton: {
    color: "#ffffff",
    backgroundColor: "var(--portal-page-primary-background)",
    border: "none",
    borderRadius: "2px",
    width: "90px",
    height: "30px",
    fontFamily: "Poppins",
  },
  anchor: {
    padding: "0.5em 2em",
  },
};

export default Button;

exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1cVgzKK1sjmBGsxKTKjBbN {\n  display: flex;\n}\n\n._2H723P6SvQLDnubQtsRAGr {\n  background: none;\n  border: none;\n  cursor: pointer !important;\n}\n\n._3nR-07w2MwQISDEWTo0XJ8 {\n  width: 16px;\n}\n._3nR-07w2MwQISDEWTo0XJ8.yr6mIfIAtCvh1hYYHLe41 {\n  transform: rotate(180deg);\n}\n._3nR-07w2MwQISDEWTo0XJ8._2aIIu00eACe4yLCXLeHX8f {\n  display: block;\n}\n\n.W25VscIfEC7dL5yKH2yKb {\n  font-family: \"Poppins\", \"sans-serif\", -apple-system, monospace !important;\n  font-style: normal;\n  font-weight: bold;\n  font-size: 12px !important;\n  margin: 0.5em;\n}\n\n._3eNXH4Xifv6DBM9vhnGbVA {\n  font-family: \"Poppins\", \"sans-serif\", -apple-system, monospace !important;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 12px !important;\n  margin: 0.5em;\n}", ""]);

// Exports
exports.locals = {
	"paginationWrapper": "_1cVgzKK1sjmBGsxKTKjBbN",
	"navButtons": "_2H723P6SvQLDnubQtsRAGr",
	"paginationArrow": "_3nR-07w2MwQISDEWTo0XJ8",
	"rightArrow": "yr6mIfIAtCvh1hYYHLe41",
	"leftArrow": "_2aIIu00eACe4yLCXLeHX8f",
	"currentPageNumber": "W25VscIfEC7dL5yKH2yKb",
	"pageNumber": "_3eNXH4Xifv6DBM9vhnGbVA"
};
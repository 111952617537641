import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { FadeLoader } from "react-spinners";
import PremiumCard from "../../components/PremiumCard/PremiumCard";
import styles from "./ResearchProduct.module.scss";
import KnowledgeCenterContext from "../../components/Context/KnowledgeCenterContext";
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import SectionWrapper from "../../components/SectionWrapper/SectionWrapper";

const ResearchProduct = () => {
  const { researchItems, isLoading } = useContext(KnowledgeCenterContext);
  const [imageSources, setImageSources] = useState([]);
  const [isLoadingImages, setIsLoadingImages] = useState(false);
  const wpEndPoint = process.env.REACT_APP_WP_API_URL;

  useEffect(() => {
    const getImageSources = async () => {
      const imageSourceArray = await Promise.all(
        researchItems.map(async (item, index) => {
          const imageId =
            item[`research_product_data__${index}_research_product_image`];

          const getImageUrl = await axios.get(
            `${wpEndPoint}wp/v2/media/${Number(imageId)}`
          );

          return getImageUrl?.data?.media_details?.sizes?.full?.source_url;
        })
      );

      setImageSources(imageSourceArray);
    };

    getImageSources();

    if (isLoading && imageSources.length === 0) {
      setIsLoadingImages(true);
    } else {
      setIsLoadingImages(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingImages]);

  return (
    <SectionWrapper>
      <SectionHeader
        header="research_products_title"
        subHeader="research_products_description"
      />
      <div className={styles.premiumCardsWrapper}>
        {isLoadingImages ? (
          <div className={styles.loaderContainer}>
            <FadeLoader
              height={20}
              width={5}
              radius={10}
              margin={10}
              color="#999"
              loading={isLoadingImages}
            />
          </div>
        ) : (
          researchItems.map((value, index) => {
            const title =
              value[`research_product_data__${index}_research_product_name`] ??
              "";

            const subHeader =
              value[
                `research_product_data__${index}_research_product_subheader`
              ] ?? "";

            const link =
              value[`research_product_data__${index}_research_product_link`] ??
              "";

            return (
              <PremiumCard
                index={index}
                key={`research-card-${index}`}
                title={title}
                imageSource={imageSources[index]}
                link={link}
                subHeader={subHeader}
              />
            );
          })
        )}
      </div>
    </SectionWrapper>
  );
};

export default ResearchProduct;

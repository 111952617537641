import React, { useState, useEffect } from "react";
import axios from "axios";

const Books = (props) => {
  const { appNavHandler } = props;
  const [books, setBooks] = useState<Array<any>>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [featuredImages, setFeaturedImages] = useState<Array<any>>([]);

  useEffect(() => {
    const wpEndPoint = process.env.REACT_APP_WP_API_URL;

    const getImageUrlHandler = async (post) => {
      const getImageUrl = await axios.get(
        `${wpEndPoint}wp/v2/media/${post.featured_media}`
      );

      return getImageUrl.data.media_details.sizes.full.source_url;
    };

    const fetchBooks = async () => {
      let books;
      let bookFeaturedImg;

      try {
        books = await axios.get(`${wpEndPoint}wp/v2/books`);
      } catch (error) {
        throw error;
      }

      if (books.data.length > 0) {
        setBooks(books.data);

        bookFeaturedImg = await Promise.all(
          books.data.map((book) => {
            return getImageUrlHandler(book);
          })
        );

        setFeaturedImages(bookFeaturedImg);
        setIsLoaded(true);
      }
    };

    fetchBooks();
  }, []);

  return (
    <div>
      {isLoaded ? (
        books.map((book, index) => {
          return (
            <>
              <h4>{book.title.rendered}</h4>
              <img src={featuredImages[index]} alt={book.title.rendered} />
              <div
                dangerouslySetInnerHTML={{ __html: book.excerpt.rendered }}
              />
              <button onClick={() => appNavHandler("book", book.id)}>
                Visit Post
              </button>
            </>
          );
        })
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default Books;

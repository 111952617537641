exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".dSuZh_Yq6YXIpxPbC5wyQ {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 1.5em;\n  align-items: center;\n  margin-top: 40px;\n}\n\n.ejlrXRJwR5ILd6ALwjRyC {\n  width: 100%;\n}\n\n._1Jf2u14vhCKLWK0dr97ApL {\n  margin-left: 1em;\n}\n\n._3qjqQknjyqDsWjhidjyj3i {\n  font-family: \"Poppins\", \"sans-serif\", -apple-system, monospace !important;\n  font-style: normal;\n  font-weight: 800;\n  font-size: 22px;\n  margin-top: 0.5em !important;\n  color: #373f4e !important;\n}\n\n._2H1PDPNVLQYlAYF46wUoxs {\n  p-font-family: \"Poppins\", \"sans-serif\", -apple-system, monospace !important;\n  p-color: #373f4e !important;\n}\n\n@media (min-width: 768px) {\n  .dSuZh_Yq6YXIpxPbC5wyQ {\n    display: grid;\n    margin-bottom: 1.5em;\n    grid-template-columns: 16em 1fr;\n    grid-gap: 1em;\n  }\n\n  .ejlrXRJwR5ILd6ALwjRyC {\n    width: 225px;\n    object-fit: contain;\n  }\n}", ""]);

// Exports
exports.locals = {
	"imageTitleContainer": "dSuZh_Yq6YXIpxPbC5wyQ",
	"featuredImage": "ejlrXRJwR5ILd6ALwjRyC",
	"titleExcerptContainer": "_1Jf2u14vhCKLWK0dr97ApL",
	"contentTitle": "_3qjqQknjyqDsWjhidjyj3i",
	"excerptContainer": "_2H1PDPNVLQYlAYF46wUoxs"
};
import React from "react";
import styles from "./ContentCard.module.scss";
import MoreButton from "../../components/MoreBtn/MoreBtn";
import { truncate } from "../../lib/utils";

const ContentCard = (props) => {
  const { post, actionHandler, index, key } = props;
  const excerpt = truncate(post?.excerpt?.rendered, 169);

  return (
    <div className={styles.contentContainer} key={key}>
      <img
        onClick={actionHandler}
        src={post.featuredImage}
        alt={`info-pack-content-${index}`}
        className={styles.contentImage}
      />
      <h4
        onClick={actionHandler}
        dangerouslySetInnerHTML={{ __html: post?.title?.rendered }}
        className={styles.postTitle}
      />
      <div
        className={styles.postExcerpt}
        dangerouslySetInnerHTML={{ __html: excerpt }}
      />
      <MoreButton actionHandler={actionHandler} />
    </div>
  );
};

export default ContentCard;

exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2xeso6ltkkBMLV50ltGQ_v {\n  background: #ffffff;\n  border: 1px solid #dfdfdf;\n  box-sizing: border-box;\n  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);\n  border-radius: 5px;\n  padding: 1.5em;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: 300px;\n  max-width: 400px;\n  margin: 1em;\n}\n\n._2ZQ1nbYqGj-LlXxuScB_7y {\n  font-weight: 500;\n  font-size: 12px !important;\n  font-family: \"Poppins\", \"sans-serif\", -apple-system, monospace !important;\n  color: var(--portal-page-primary-text) !important;\n  margin-top: 10px;\n  text-align: center;\n}\n\n._32szfPkcFk_FosbrjXSr6Z {\n  text-transform: capitalize;\n  font-family: \"Poppins\", \"sans-serif\", -apple-system, monospace !important;\n  font-style: normal;\n  font-weight: bold;\n  font-size: 18px !important;\n  text-align: center;\n  color: #333333 !important;\n  margin-top: 0.5em !important;\n  text-align: center;\n}\n\n._2qttMPr_ZNxAbl7mtcL0uQ {\n  cursor: pointer;\n  font-family: \"Poppins\", \"sans-serif\", -apple-system, monospace !important;\n}\n\n._8fuRXUtmq64gYoIEK7Hhr {\n  width: 300px;\n  max-width: 400px;\n  display: flex;\n  justify-content: center;\n  height: 285px;\n  max-height: 400px;\n}\n\n._2mB7xnC5ez9LdxK-3plky- {\n  max-width: 300px;\n  max-height: 275px;\n}", ""]);

// Exports
exports.locals = {
	"premiumCardContainer": "_2xeso6ltkkBMLV50ltGQ_v",
	"freeBook": "_2ZQ1nbYqGj-LlXxuScB_7y",
	"subHeaderCard": "_32szfPkcFk_FosbrjXSr6Z",
	"discoverMore": "_2qttMPr_ZNxAbl7mtcL0uQ",
	"imageContainer": "_8fuRXUtmq64gYoIEK7Hhr",
	"premiumImage": "_2mB7xnC5ez9LdxK-3plky-"
};
import React, { useState, useEffect } from "react";
import axios from "axios";
import clsx from "clsx";
import phone from "../../images/phone-mockup.png";
import styles from "./PodcastTab.module.scss";
import itunesButton from "../../images/itune-button.png";
import googleButton from "../../images/google-button.png";
import spotifyButton from "../../images/spotify-button.png";
import playButton from "../../images/play-solid.svg";
import Icon from "../../components/Icon/Icon";
import arrow from "../../images/arrow.svg";
import Loader from "../../components/Loader/Loader";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import { useCategory } from "../../services/get-category";
import { podCastLinks } from "../../lib/config";

const PodcastTab = () => {
  const [count, setCount] = useState(1);
  const [paginationNumber, setPaginationNumber] = useState([]);
  const [postPerPage, setPostPerPage] = useState([]);
  const [isPodcastLoaded, setIsPodcastLoaded] = useState(false);
  const [passNumberPagesError, setPassNumberPagesError] = useState(false);
  const podcastCategory = useCategory("podcast");
  const { itunes, spotify, google } = podCastLinks;

  const number_pages = podcastCategory.isSuccess
    ? Math.ceil(podcastCategory.data[0].count / 10)
    : 1;

  const setCountHandler = (type) => {
    if (type === "previous") {
      if (count === 1) {
        setCount(1);
      } else {
        setCount((count) => count - 1);
      }
    }

    if (type === "next") {
      if (count === number_pages) {
        setCount((count) => count);
      } else {
        setCount((count) => count + 1);
      }
    }
  };

  useEffect(() => {
    const getPodcastList = async () => {
      let getPerPage;

      try {
        getPerPage = await axios.get(
          `${process.env.REACT_APP_PODCAST_URI}wp-json/wp/v2/posts?page=${count}&per_page=10&categories=${process.env.REACT_APP_PODCAST_CATEGORY}`
        );
      } catch (error) {
        if (
          error.response.status === 400 &&
          error.response.data.code === "rest_post_invalid_page_number"
        ) {
          setCount((count) => count - 1);
          setPassNumberPagesError(true);

          setTimeout(() => {
            setPassNumberPagesError(false);
          }, 3000);
        }

        throw error;
      }

      if (getPerPage.data.length > 0) {
        setPostPerPage([...getPerPage.data]);
        setIsPodcastLoaded(true);
      }
    };

    getPodcastList();
    const initArray = [1, 2, 3];
    const addingArray = [count - 2, count - 1, count, count + 1, count + 2];
    const lastArray = [count - 2, count - 1, count];

    if (count >= 1 && count <= 2) {
      setPaginationNumber([...initArray]);
    } else if (count <= number_pages && count >= number_pages - 1) {
      setPaginationNumber([...lastArray]);
    } else {
      setPaginationNumber([...addingArray]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, number_pages]);

  return (
    <>
      <div className={styles.wholePodCastSectionWrapper}>
        <div className={styles.podCastImgSection}>
          <div className={styles.phoneImgContainer}>
            <img src={phone} alt="phone-podcast" />
          </div>
          <div className={styles.rectBackground}>
            <div className={styles.twoBtnContainer}>
              <div className={styles.podcastButtonContainer}>
                <a href={itunes} rel="noopener noreferrer" target="_blank">
                  <img src={itunesButton} alt="itune-button" />
                </a>
              </div>
              <div className={styles.podcastButtonContainer}>
                <a href={spotify} rel="noopener noreferrer" target="_blank">
                  <img src={spotifyButton} alt="spotify-button" />
                </a>
              </div>
            </div>
            <div className={styles.soloButtonWrapper}>
              <div className={styles.podcastButtonContainer}>
                <a href={google} rel="noopener noreferrer" target="_blank">
                  <img src={googleButton} alt="google-button" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.podCastListSection}>
          <h4 className={styles.startListening}>Start Listening to:</h4>
          {isPodcastLoaded ? (
            postPerPage.map((item) => {
              const title = item.title.rendered;

              return (
                <div className={styles.podcastLinkWrapper}>
                  <a href={item.link} rel="noopener noreferrer" target="_blank">
                    <Icon
                      source={playButton}
                      rotate={false}
                      iconStyle={iconStyle}
                    />
                  </a>
                  <a href={item.link} rel="noopener noreferrer" target="_blank">
                    <p
                      className={styles.podcastTitle}
                      dangerouslySetInnerHTML={{ __html: title }}
                    />
                  </a>
                </div>
              );
            })
          ) : (
            <Loader />
          )}
          <div className={styles.paginationContainer}>
            <img
              className={clsx(styles.paginationArrow, styles.leftArrow)}
              src={arrow}
              alt="prev-arrow"
              onClick={() => setCountHandler("previous")}
            />
            {paginationNumber.map((item, index) => {
              const first = index === 0;

              const isBold = (
                <p
                  className={clsx(
                    styles.countDisplay,
                    first && styles.firstCount,
                    styles.isBold
                  )}
                >
                  {item}
                </p>
              );

              const isNotBold = (
                <p
                  className={clsx(
                    styles.countDisplay,
                    first && styles.firstCount
                  )}
                >
                  {item}
                </p>
              );

              let content;

              if (index + 1 === count && count === 1) {
                content = isBold;
              } else if (index + 1 === count && count === 2) {
                content = isBold;
              } else if (index + 1 === count && count === 3) {
                content = isBold;
              } else if (count > 3 && index === 2) {
                content = isBold;
              } else {
                content = isNotBold;
              }

              return content;
            })}
            <img
              className={clsx(styles.paginationArrow, styles.rightArrow)}
              src={arrow}
              alt="next-arrow"
              onClick={() => setCountHandler("next")}
            />
          </div>
          {passNumberPagesError ? (
            <div className={styles.errorContainer}>
              <ErrorMessage error="Exceeded The Number Of Pages Available" />
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

const iconStyle = {
  width: "15px",
};

export default PodcastTab;

import axios from "axios";

const wpEndPoint = process.env.REACT_APP_WP_BASE_API_URL;
const rand = Math.floor(Math.random() * 10);

export const getRelatedPostsCount = async (currentID, currentCategory) =>
  await axios.get(
    `${wpEndPoint}wp-json/wp/v2/posts?_embed&categories=${currentCategory}&exclude=${currentID}&v=${rand}`
  );

export const getRelatedPosts = async (
  currentPage,
  currentCategory,
  currentID
) =>
  await axios.get(
    `${wpEndPoint}wp-json/wp/v2/posts?_embed&page=${currentPage}&per_page=4&categories=${currentCategory}&exclude=${currentID}&v=${rand}`
  );

export const serviceConfig = {
  first: "our_professional_service",
  firstSub: 24,
  ending1: "title",
  ending1Sub: 5,
  ending2: "icon",
  ending2Sub: 4,
  ending3: "_link",
  ending3Sub: 5,
  exclude: "our_professional_service_more_link",
  mainkeyProp: "our_professional_service__",
};

export const premiumConfig = {
  first: "premium_resources_data__",
  firstSub: 24,
  ending1: "name",
  ending1Sub: 4,
  ending2: "image",
  ending2Sub: 5,
  ending3: "link",
  ending3Sub: 4,
  ending4: "subheader",
  ending4Sub: 9,
  exclude: "",
  mainkeyProp: "premium_resources_data__",
};

export const researchConfig = {
  first: "research_product_data",
  firstSub: 21,
  ending1: "name",
  ending1Sub: 4,
  ending2: "image",
  ending2Sub: 5,
  ending3: "link",
  ending3Sub: 4,
  ending4: "subheader",
  ending4Sub: 9,
  exclude: "",
  mainkeyProp: "research_product_data__",
};

export const footerConfigPremium = {
  first: "footer_premium",
  firstSub: 14,
  ending1: "link",
  ending1Sub: 4,
  ending2: "name",
  ending2Sub: 4,
  ending3: "",
  ending3Sub: null,
  exclude: "",
  mainkeyProp: "footer_premium_items_",
};

export const footerConfigTutorial = {
  first: "footer_tutorial",
  firstSub: 15,
  ending1: "post",
  ending1Sub: 4,
  ending2: "name",
  ending2Sub: 4,
  ending3: "",
  ending3Sub: null,
  exclude: "",
  mainkeyProp: "footer_tutorial_items_",
};

export const knowledgeColumn = [
  { name: "Home", link: "home", featured: "" },
  { name: "Blogs", link: "blog", featured: "" },
  { name: "Videos", link: "videos", featured: "" },
  { name: "Podcasts", link: "podcasts", featured: "" },
  {
    name: "Support",
    link: "https://wealthpathlabs.atlassian.net/servicedesk/customer/portal/2",
    featured: "",
  },
];

export const infoPackConfig = {
  first: "info_pack",
  firstSub: 9,
  ending1: "file",
  ending1Sub: 4,
  ending2: "name",
  ending2Sub: 4,
  exclude: "",
  mainkeyProp: "info_pack_downloadables_",
};

export const infocPackCheckListConfig = {
  first: "info_pack",
  firstSub: 9,
  ending1: "includes",
  ending1Sub: 8,
  exclude: "",
  mainkeyProp: "info_pack_check_list_",
};

export const infoPackPagesConfig = {
  first: "info_pack_pages",
  firstSub: 15,
  ending1: "chosen_pages",
  ending1Sub: 12,
  exlude: "",
  mainkeyProp: "info_pack_pages_",
};

export const infoPackAddedPostConfig = {
  first: "info_pack_page_posts",
  firstSub: 20,
  ending1: "added_posts",
  ending1Sub: 11,
  exlude: "",
  mainkeyProp: "info_pack_page_posts_",
};

export const podCastLinks = {
  itunes:
    "https://podcasts.apple.com/au/podcast/the-property-couch/id969904333?ls=1&mt=2",
  spotify:
    "https://open.spotify.com/show/09ofZB3Y6309Q88HZSzlop?si=V9YLflUORyqeg4rfFrjbzA",
  google:
    "https://podcasts.google.com/feed/aHR0cDovL3RoZXByb3BlcnR5Y291Y2guY29tLmF1L2ZlZWQv",
};

exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._3MROHqf2-H_LPqWFEgsWG5 {\n  display: flex;\n  flex-direction: column;\n}\n\n._2rJghFk_QX7FvkuPAttUss {\n  margin-right: 3.5em;\n  display: flex;\n  flex-direction: column;\n}\n\n._2h8QG7OerA7UvA1GPu7uAA {\n  font-family: \"Poppins\", \"sans-serif\", -apple-system, monospace !important;\n  font-style: normal;\n  font-weight: bold;\n  font-size: 14px !important;\n  text-transform: uppercase !important;\n  margin-bottom: 20px;\n  margin-top: 10px;\n}\n\n._1O0t2t4DJ66uSUDrUreE56 {\n  font-family: \"Poppins\", \"sans-serif\", -apple-system, monospace !important;\n  font-style: normal;\n  font-weight: normal;\n  font-size: 14px !important;\n  cursor: pointer;\n  color: #333333 !important;\n  margin-bottom: 1.5em;\n}\n\n@media (min-width: 768px) {\n  ._3MROHqf2-H_LPqWFEgsWG5 {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n  }\n}", ""]);

// Exports
exports.locals = {
	"footerContainer": "_3MROHqf2-H_LPqWFEgsWG5",
	"columnContainer": "_2rJghFk_QX7FvkuPAttUss",
	"footerNavTitle": "_2h8QG7OerA7UvA1GPu7uAA",
	"footerItem": "_1O0t2t4DJ66uSUDrUreE56"
};
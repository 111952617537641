import React, { useState, useEffect } from "react";
import Books from "./components/Books/Books";
import styles from "./App.module.scss";
import Home from "./pages/Home/Home";
import AppContext from "./components/Context/AppContext";
import Page from "./pages/Page/Page";
import Post from "./pages/Post/Post";
import Blogs from "./pages/Blogs/Blogs";
import Support from "./pages/Support/Support";
import Podcasts from "./pages/Podcasts/Podcasts";
import Videos from "./pages/Videos/Videos";
import InfoPackPage from "./pages/InfoPackPage/InfoPackPage";
import { useKnowledgeCenter } from "./services/get-knowledge-center";

interface AppProps {
  id?: string;
  isAdmin?: Array<any>;
}

const App: React.FC<AppProps> = () => {
  const [contentId, setContentId] = useState(null);
  const [wpTaxonomy, setWpTaxonomy] = useState("");
  const [featuredMedia, setFeaturedMedia] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [scrollToLib, setScrollToLib] = useState(false);
  const [scrollHome, setScrollHome] = useState(false);
  const knowledgeCenterData = useKnowledgeCenter();
  useEffect(() => {}, [wpTaxonomy, contentId]);

  const appNavHandler = (
    type: string,
    id: string | number,
    featured: string
  ) => {
    setWpTaxonomy(type);
    setContentId(id);
    setFeaturedMedia(featured);
  };

  const appNavLibrary = (
    type: string,
    tabValue: number,
    scroll: boolean,
    scrollHome: boolean
  ) => {
    setWpTaxonomy(type);
    setTabValue(tabValue);
    setScrollToLib(scroll);
    setScrollHome(scrollHome);
  };

  const appNavHome = (
    type: string,
    scrollHome: boolean,
    scrollLib: boolean
  ) => {
    setWpTaxonomy(type);
    setScrollHome(scrollHome);
    setScrollToLib(scrollLib);
  };

  let content;

  switch (wpTaxonomy) {
    case "list":
      content = <Books />;
      break;
    case "page":
      content = <Page />;
      break;
    case "post":
      content = <Post />;
      break;
    case "infopack":
      content = <InfoPackPage />;
      break;
    case "home":
      content = <Home />;
      break;
    case "support":
      content = <Support />;
      break;
    case "blog":
      content = <Blogs />;
      break;
    case "podcasts":
      content = <Podcasts />;
      break;
    case "videos":
      content = <Videos />;
      break;
    default:
      content = <Home />;
  }

  return (
    <div className={styles.container}>
      <AppContext.Provider
        value={{
          appNavHandler,
          contentId,
          featuredMedia,
          knowledgeCenterData,
          tabValue,
          setTabValue,
          appNavLibrary,
          scrollToLib,
          scrollHome,
          appNavHome,
          setScrollToLib,
          setScrollHome,
        }}
      >
        {content}
      </AppContext.Provider>
    </div>
  );
};

export default App;

exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1u2sQGCu6SGFsrsm-xmBNy {\n  font-family: \"Poppins\", \"sans-serif\", -apple-system, monospace !important;\n}\n\n._1ipswy81Jkr1-qNEMxarBQ {\n  font-family: \"Poppins\", \"sans-serif\", -apple-system, monospace !important;\n  font-style: normal;\n  font-weight: 800;\n}\n\n._131KbsEoe8yr4RNQ4GpTen {\n  display: flex;\n  justify-content: center;\n}\n\n.-jUsmFjcf8dnxNrsEFNtK {\n  width: 80%;\n}\n\n.y4aTJP8JR5nnLU826W-DV {\n  width: 80%;\n  margin-top: 2em;\n  margin-bottom: 2em;\n}", ""]);

// Exports
exports.locals = {
	"wpContentContainer": "_1u2sQGCu6SGFsrsm-xmBNy",
	"pageTitle": "_1ipswy81Jkr1-qNEMxarBQ",
	"loaderContainer": "_131KbsEoe8yr4RNQ4GpTen",
	"imageContainer": "-jUsmFjcf8dnxNrsEFNtK",
	"featuredImageStyle": "y4aTJP8JR5nnLU826W-DV"
};
import React from "react";
import { Box, Typography } from "@material-ui/core";

export const TabPanel = (props: any) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      aria-labelledby={`nav-tab-${index}`}
      id={`nav-tabpanel-${index}`}
      hidden={value !== index}
      role="tabpanel"
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._2RfIraV_BhDbfbXzsk5snq {\n  width: 100%;\n  margin-right: 1em;\n}\n\n._2k7kuBk-Zi8huo83H_mm7 p {\n  font-family: \"Poppins\", \"sans-serif\", -apple-system, monospace !important;\n}\n\n._3wdXiRkA2ZVVXnOGuKGaIB {\n  font-family: \"Poppins\", \"sans-serif\", -apple-system, monospace !important;\n  font-style: normal;\n  font-weight: bold;\n  font-size: 18px !important;\n  color: #333333 !important;\n  text-align: left;\n  cursor: pointer;\n}\n\n.I1lTVYrZFcRlM3yJNKE1e {\n  width: 100%;\n  height: auto;\n}\n\n@media (min-width: 1280px) {\n  ._2RfIraV_BhDbfbXzsk5snq {\n    width: calc(25% - 20px);\n  }\n\n  .I1lTVYrZFcRlM3yJNKE1e {\n    width: 200px;\n    height: 150px;\n    object-fit: cover;\n  }\n}\n@media (min-width: 1360px) {\n  .I1lTVYrZFcRlM3yJNKE1e {\n    width: 100%;\n    object-fit: cover;\n  }\n}\n@media (min-width: 1920px) {\n  .I1lTVYrZFcRlM3yJNKE1e {\n    width: 355px;\n    height: 200px;\n    object-fit: cover;\n  }\n}", ""]);

// Exports
exports.locals = {
	"contentContainer": "_2RfIraV_BhDbfbXzsk5snq",
	"postExcerpt": "_2k7kuBk-Zi8huo83H_mm7",
	"postTitle": "_3wdXiRkA2ZVVXnOGuKGaIB",
	"contentImage": "I1lTVYrZFcRlM3yJNKE1e"
};
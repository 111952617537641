import React, { useContext } from "react";
import GoBackButton from "../../components/GoBackBtn/GoBackBtn";
import AppContext from "../../components/Context/AppContext";
import PodcastTab from "../../components/PodcastTab/PodcastTab";

const Podcasts = () => {
  const { appNavHandler } = useContext(AppContext);

  return (
    <div>
      <GoBackButton action={() => appNavHandler("home", null, "")} />
      <PodcastTab />
    </div>
  );
};

export default Podcasts;

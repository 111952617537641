import React from "react";
import { FadeLoader } from "react-spinners";
import styles from "./Loader.module.scss";

const Loader = (props) => {
  const { isLoading } = props;

  return (
    <div className={styles.loaderContainer}>
      <FadeLoader
        height={20}
        width={5}
        radius={10}
        margin={10}
        color="#999"
        loading={isLoading}
      />
    </div>
  );
};

export default Loader;

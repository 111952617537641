exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._1ghty_c0BzKg2sP-i8Aidr {\n  font-family: \"Poppins\", \"sans-serif\", -apple-system, monospace !important;\n  font-style: normal;\n  font-weight: 800;\n  font-size: 34px;\n  margin-top: 10px;\n}\n\n._2WTTO4uS_j07WxtfzY-uw3 {\n  font-family: \"Poppins\", \"sans-serif\", -apple-system, monospace !important;\n  font-style: normal;\n  font-weight: 500 !important;\n  font-size: 26px !important;\n  color: #e0e0e0 !important;\n  text-transform: uppercase;\n}\n\n._20Hc4B3GodwuWmfntUZP5y {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  position: fixed;\n  width: 100%;\n  top: 0;\n  left: 0;\n}\n\n._1CqBy06pK__wNtnerAVmLh {\n  color: #a94442 !important;\n}\n\n.aYuGxpmucVYoWtMxLA7-v {\n  font-family: \"Poppins\", \"sans-serif\", -apple-system, monospace !important;\n  font-style: normal;\n  font-weight: 500 !important;\n  font-size: 26px !important;\n  color: #e0e0e0 !important;\n}", ""]);

// Exports
exports.locals = {
	"searchBoxTitle": "_1ghty_c0BzKg2sP-i8Aidr",
	"knowledgeCenterText": "_2WTTO4uS_j07WxtfzY-uw3",
	"loaderContainer": "_20Hc4B3GodwuWmfntUZP5y",
	"errorMessage": "_1CqBy06pK__wNtnerAVmLh",
	"knowledgeHeader": "aYuGxpmucVYoWtMxLA7-v"
};
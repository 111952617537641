import React, { useContext } from "react";
import GoBackButton from "../../components/GoBackBtn/GoBackBtn";
import AppContext from "../../components/Context/AppContext";

const Blogs = () => {
  const { appNavHandler } = useContext(AppContext);

  return (
    <div>
      <GoBackButton action={() => appNavHandler("home", null, "")} />
      <h1>Blog Page</h1>
    </div>
  );
};

export default Blogs;

import { createContext } from "react";

type ContextProps = {
  knowledgeCenter: any;
  serviceItems: any;
  researchItems: any;
  premiumItems: any;
  isLoading: boolean;
  isInfoPagesLoaded: any;
  infoPackPages: any;
  scrollToLibrary: (tab: number) => void;
  tabValue: number;
  setTabValue: any;
  scrollTop: () => void;
};

const KnowledgeCenterContext = createContext<Partial<ContextProps>>({});

export default KnowledgeCenterContext;

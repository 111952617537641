import React, { useContext } from "react";
import clsx from "clsx";
import styles from "./SectionHeader.module.scss";
import {
  getDeepObjMetas as getDeepObjectMetas,
  checkFalsy,
} from "../../lib/utils";
import AppContext from "../Context/AppContext";

const SectionHeader = (props) => {
  const { header, subHeader, big = false } = props;
  const { knowledgeCenterData } = useContext(AppContext);
  const fetchHeader = getDeepObjectMetas(knowledgeCenterData, header);
  const fetchSubHeader = checkFalsy(subHeader);

  return (
    <div>
      <h4
        className={clsx(
          styles.featuredInfoHeader,
          big ? styles.bigHeader : null
        )}
      >
        {fetchHeader}
      </h4>
      {!fetchSubHeader ? (
        <p className={styles.subHeader}>
          {getDeepObjectMetas(knowledgeCenterData, subHeader)}
        </p>
      ) : null}
    </div>
  );
};

export default SectionHeader;

import { useState, useEffect } from "react";

const usePage = (initialPage = 0, numberOfPages, perPage, offset) => {
  // const offset = 1000 // Limit returned by API
  // const perPage = 8 // Number of items per page, offset should be divisible by this
  const [page, setPage] = useState(initialPage); // page used by UI
  const pageToQuery = Math.floor(page / (offset / perPage)) + 1; // page used by api
  const [pageRange, setPageRange] = useState([]); // page used by UI
  const [control, setControl] = useState("next");

  const nextPage = () => {
    setPage((p) => p + 1);
    setControl("next");
  };

  const previousPage = () => {
    setPage((p) => p - 1);
    setControl("previous");
  };

  useEffect(() => {
    if (numberOfPages === 2) setPageRange([1, 2]);
    else if (numberOfPages > 2) setPageRange([1, 2, 3]);
  }, [numberOfPages]);

  useEffect(() => {
    if (control === "previous" && page !== 0) {
      setPageRange([page, page + 1, page + 2]);
    } else if (control === "next" && page >= 2 && page !== numberOfPages - 1) {
      setPageRange([page, page + 1, page + 2]);
    }
  }, [page, control, numberOfPages]);

  const hasPrevious = () => page > 0;
  const hasNext = (totalCount: number) => (page + 1) * perPage < totalCount;
  // Sub Page
  const subPages = offset / perPage;

  const subIndexStart =
    (page - subPages * Math.floor(page / subPages)) * perPage;

  const subIndexEnd = subIndexStart + perPage;

  return {
    page,
    setPage,
    nextPage,
    prevPage: previousPage,
    hasPrev: hasPrevious,
    hasNext,
    perPage,
    pageToQuery,
    subIndexStart,
    subIndexEnd,
    pageRange,
  };
};

export default usePage;

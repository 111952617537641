import { useQuery } from "react-query";
import axios from "axios";

const wpEndPoint = process.env.REACT_APP_WP_API_URL;

const getSearch = async (userSearch) => {
  const data = await axios.get(
    `${wpEndPoint}wp/v2/posts/?search=${userSearch}`
  );

  const dataPage = await axios.get(
    `${wpEndPoint}wp/v2/pages/?search=${userSearch}`
  );

  const allresult = [...data.data, ...dataPage.data];
  data.data = allresult;

  return data;
};

export const useSearch = (search: string, enabled: boolean) =>
  useQuery(
    ["search", search],
    async () => {
      const response = await getSearch(search);

      return response.data;
    },
    {
      enabled,
    }
  );

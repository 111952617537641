import React from "react";
import styles from "./IconSlot.module.scss";

const IconSlot = (props) => {
  const { title, imageSource, index, link } = props;

  // <a href={link} target="_blank" rel="noopener noreferrer">
  return (
    <div className={styles.iconContainer}>
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.iconTitle}
      >
        <img src={imageSource} alt={`icon-${index}`} />
      </a>
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.iconTitle}
      >
        {title}
      </a>
    </div>
  );
};

export default IconSlot;

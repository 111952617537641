import formatNumber from "format-number";
import axios from "axios";
import _ from "lodash";
import { ClientSchema } from "../schemas/clientSummaryResponseSchema.json";

const wpEndPoint = process.env.REACT_APP_WP_API_URL;

export const getFullName = (client: ClientSchema): string => {
  return `${client.fName} ${client.mName} ${client.lName}`;
};

export const formatCurrency = formatNumber({
  prefix: "$",
  round: 2,
  padRight: 2,
});

export const getImageUrlHandler = async (featured_media) => {
  const getImageUrl = await axios.get(
    `${wpEndPoint}wp/v2/media/${featured_media}`
  );

  let content;

  content = getImageUrl
    ? getImageUrl.data.media_details.sizes.full.source_url
    : null;

  return content;
};

export const concatFeaturedImages = (mediaArray, results) => {
  return results.data.map((result) => {
    const foundSimilar = mediaArray.data.find(
      (res) => res.id === mediaArray.postId
    );

    result.featuredImage = foundSimilar.featuredLink;

    return result;
  });
};

export const getDeepObjMetas = (object, propertyName) => {
  return object?.data?.metas[propertyName][0];
};

export const getCustomImageTitle = (object, config) => {
  const {
    first,
    firstSub,
    ending1,
    ending1Sub,
    ending2,
    ending2Sub,
    exclude,
    mainkeyProp,
  } = config;

  const newObjectArray = _.pickBy(object?.data?.metas, (value, key) => {
    if (exclude) {
      return (
        key.slice(0, Math.max(0, firstSub)) === first &&
        key !== exclude &&
        (key.slice(Math.max(0, key.length - ending1Sub)) === ending1 ||
          key.slice(Math.max(0, key.length - ending2Sub)) === ending2)
      );
    }

    return (
      key.slice(0, Math.max(0, firstSub)) === first &&
      (key.slice(Math.max(0, key.length - ending1Sub)) === ending1 ||
        key.slice(Math.max(0, key.length - ending2Sub)) === ending2)
    );
  });

  const ordered = {};

  Object.keys(newObjectArray)
    .sort()
    .forEach((key) => {
      ordered[key] = newObjectArray[key];
    });

  return Object.keys(ordered).reduce(
    (currentArray, objectKey) => {
      let currentIndex = currentArray.length - 1;
      let mainKey = `${mainkeyProp}${currentIndex}`;

      const totalCount = Object.keys(ordered).filter((item) =>
        item.includes(mainKey)
      ).length;

      if (Object.keys(currentArray[currentIndex]).length === totalCount) {
        mainKey = `${mainkeyProp}${(currentIndex += 1)}`;
      }

      const newObject =
        {
          ...currentArray[currentIndex],
        } || {};

      if (objectKey.includes(mainKey)) {
        newObject[objectKey] = ordered[objectKey][0];
      } else {
        currentArray.push({});
      }

      currentArray[currentIndex] = newObject;

      return currentArray;
    },
    [{}]
  );
};

export const getSingleObjProp = (object, config) => {
  const { first, firstSub, ending1, ending1Sub, exclude, mainkeyProp } = config;

  const newObjectArray = _.pickBy(object?.data?.metas, (value, key) => {
    if (exclude) {
      return (
        key.slice(0, Math.max(0, firstSub)) === first &&
        key !== exclude &&
        key.slice(Math.max(0, key.length - ending1Sub)) === ending1
      );
    }

    return (
      key.slice(0, Math.max(0, firstSub)) === first &&
      key.slice(Math.max(0, key.length - ending1Sub)) === ending1
    );
  });

  const ordered = {};

  Object.keys(newObjectArray)
    .sort()
    .forEach((key) => {
      ordered[key] = newObjectArray[key];
    });

  return Object.keys(ordered).reduce(
    (currentArray, objectKey) => {
      let currentIndex = currentArray.length - 1;
      let mainKey = `${mainkeyProp}${currentIndex}`;

      const totalCount = Object.keys(ordered).filter((item) =>
        item.includes(mainKey)
      ).length;

      if (Object.keys(currentArray[currentIndex]).length === totalCount) {
        mainKey = `${mainkeyProp}${(currentIndex += 1)}`;
      }

      const newObject =
        {
          ...currentArray[currentIndex],
        } || {};

      if (objectKey.includes(mainKey)) {
        newObject[objectKey] = ordered[objectKey][0];
      } else {
        currentArray.push({});
      }

      currentArray[currentIndex] = newObject;

      return currentArray;
    },
    [{}]
  );
};

export const getThreeObjItems = (object, config) => {
  const {
    first,
    firstSub,
    ending1,
    ending1Sub,
    ending2,
    ending2Sub,
    ending3,
    ending3Sub,
    exclude,
    mainkeyProp,
  } = config;

  const newObjectArray = _.pickBy(object?.data?.metas, (value, key) => {
    if (ending3 === "" && ending3Sub === null) {
      return (
        key.slice(0, Math.max(0, firstSub)) === first &&
        key !== exclude &&
        (key.slice(Math.max(0, key.length - ending1Sub)) === ending1 ||
          key.slice(Math.max(0, key.length - ending2Sub)) === ending2)
      );
    }

    return (
      key.slice(0, Math.max(0, firstSub)) === first &&
      key !== exclude &&
      (key.slice(Math.max(0, key.length - ending1Sub)) === ending1 ||
        key.slice(Math.max(0, key.length - ending2Sub)) === ending2 ||
        key.slice(Math.max(0, key.length - ending3Sub)) === ending3)
    );
  });

  const ordered = {};

  Object.keys(newObjectArray)
    .sort()
    .forEach((key) => {
      ordered[key] = newObjectArray[key];
    });

  return Object.keys(ordered).reduce(
    (currentArray, objectKey) => {
      let currentIndex = currentArray.length - 1;
      let mainKey = `${mainkeyProp}${currentIndex}`;

      const totalCount = Object.keys(ordered).filter((item) =>
        item.includes(mainKey)
      ).length;

      if (Object.keys(currentArray[currentIndex]).length === totalCount) {
        mainKey = `${mainkeyProp}${(currentIndex += 1)}`;
      }

      const newObject =
        {
          ...currentArray[currentIndex],
        } || {};

      if (objectKey.includes(mainKey)) {
        newObject[objectKey] = ordered[objectKey][0];
      } else {
        currentArray.push({});
      }

      currentArray[currentIndex] = newObject;

      return currentArray;
    },
    [{}]
  );
};

export const getFourOjbItems = (object, config) => {
  const {
    first,
    firstSub,
    ending1,
    ending1Sub,
    ending2,
    ending2Sub,
    ending3,
    ending3Sub,
    ending4,
    ending4Sub,
    exclude,
    mainkeyProp,
  } = config;

  const newObjectArray = _.pickBy(object?.data?.metas, (value, key) => {
    if (ending3 === "" && ending3Sub === null) {
      return (
        key.slice(0, Math.max(0, firstSub)) === first &&
        key !== exclude &&
        (key.slice(Math.max(0, key.length - ending1Sub)) === ending1 ||
          key.slice(Math.max(0, key.length - ending2Sub)) === ending2)
      );
    }

    return (
      key.slice(0, Math.max(0, firstSub)) === first &&
      key !== exclude &&
      (key.slice(Math.max(0, key.length - ending1Sub)) === ending1 ||
        key.slice(Math.max(0, key.length - ending2Sub)) === ending2 ||
        key.slice(Math.max(0, key.length - ending3Sub)) === ending3 ||
        key.slice(Math.max(0, key.length - ending4Sub)) === ending4)
    );
  });

  const ordered = {};

  Object.keys(newObjectArray)
    .sort()
    .forEach((key) => {
      ordered[key] = newObjectArray[key];
    });

  return Object.keys(ordered).reduce(
    (currentArray, objectKey) => {
      let currentIndex = currentArray.length - 1;
      let mainKey = `${mainkeyProp}${currentIndex}`;

      const totalCount = Object.keys(ordered).filter((item) =>
        item.includes(mainKey)
      ).length;

      if (Object.keys(currentArray[currentIndex]).length === totalCount) {
        mainKey = `${mainkeyProp}${(currentIndex += 1)}`;
      }

      const newObject =
        {
          ...currentArray[currentIndex],
        } || {};

      if (objectKey.includes(mainKey)) {
        newObject[objectKey] = ordered[objectKey][0];
      } else {
        currentArray.push({});
      }

      currentArray[currentIndex] = newObject;

      return currentArray;
    },
    [{}]
  );
};

export const checkFalsy = (item) => {
  let check: boolean;
  check = !!(item === null || item === "" || item === undefined);

  return check;
};

export const truncate = (string: string, n: number) =>
  string.length > n ? `${string.slice(0, Math.max(0, n - 1))}&hellip;` : string;

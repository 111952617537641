exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, "._-1KACg47V1JWaHt7mYf-u {\n  font-family: \"Poppins\", \"sans-serif\", -apple-system, monospace !important;\n  font-style: normal;\n  font-weight: bold;\n  font-size: 18px;\n  color: #333333 !important;\n  text-align: left;\n  width: 100%;\n  margin-bottom: 1px !important;\n}\n\n._3wbDHUUYvv1zM1MfM3UmrD p {\n  font-family: \"Poppins\", \"sans-serif\", -apple-system, monospace !important;\n  color: #333333 !important;\n  font-size: 12px !important;\n  text-align: left !important;\n  margin: 0 !important;\n}\n\n._2Z-fRaDNq6hzXG7UZeINKD {\n  color: var(--portal-page-primary-text) !important;\n  font-family: \"Poppins\", \"sans-serif\", -apple-system, monospace !important;\n  font-size: 12px !important;\n  text-align: left;\n  cursor: pointer;\n}\n\n._1QvcM6LrJDUKBPBZKdhHVa {\n  background-color: #888;\n  content: \"No image available\";\n  font-size: 30px;\n  color: #fff;\n  display: block;\n  width: 100%;\n}\n\n.R4_GjpHXX65of802ePAC1 {\n  text-align: center;\n  width: 100%;\n  float: left;\n  display: flex;\n  flex-direction: column;\n}\n\n._3OYI3FYIV7NuMDHh5zfW5L {\n  clear: both;\n}\n\n._11j1uRPZu_IVOM3Gsm1aOO {\n  padding: 0 !important;\n}\n\n._1ZPEE3MzAusgP3njafEPsn {\n  margin-top: 10px;\n}\n\n@media (min-width: 1280px) {\n  .R4_GjpHXX65of802ePAC1 {\n    width: calc(25% - 20px);\n  }\n\n  ._1QvcM6LrJDUKBPBZKdhHVa {\n    width: 200px;\n    height: 150px;\n    object-fit: cover;\n  }\n\n  .wa9ucbqduPCewvkA-2fpb {\n    max-width: 200px;\n    max-height: 150px;\n    height: 150px;\n  }\n}\n@media (min-width: 1360px) {\n  ._1QvcM6LrJDUKBPBZKdhHVa {\n    width: 95%;\n    object-fit: cover;\n  }\n\n  .wa9ucbqduPCewvkA-2fpb {\n    max-width: 100%;\n    max-height: 200px;\n    height: 200px;\n  }\n}\n@media (min-width: 1920px) {\n  ._1QvcM6LrJDUKBPBZKdhHVa {\n    width: 355px;\n    height: 200px;\n    object-fit: cover;\n  }\n\n  .wa9ucbqduPCewvkA-2fpb {\n    max-width: 355px;\n    height: 200px;\n  }\n}", ""]);

// Exports
exports.locals = {
	"postTitle": "_-1KACg47V1JWaHt7mYf-u",
	"excerptContainer": "_3wbDHUUYvv1zM1MfM3UmrD",
	"moreButton": "_2Z-fRaDNq6hzXG7UZeINKD",
	"featured_image": "_1QvcM6LrJDUKBPBZKdhHVa",
	"post_entry": "R4_GjpHXX65of802ePAC1",
	"clearfix": "_3OYI3FYIV7NuMDHh5zfW5L",
	"postTitleButton": "_11j1uRPZu_IVOM3Gsm1aOO",
	"moreBtnWrapper": "_1ZPEE3MzAusgP3njafEPsn",
	"featuredContainer": "wa9ucbqduPCewvkA-2fpb"
};
import { useQuery } from "react-query";
import axios from "axios";

const wpEndPoint = process.env.REACT_APP_WP_BASE_API_URL;
const rand = Math.floor(Math.random() * 10);

const getLibrary = async () =>
  await axios.get(`${wpEndPoint}knowledge-center-final/?headless=1.${rand}`);

export const fetchCategory = async (currentPage, selectedCategory) =>
  selectedCategory &&
  (await axios.get(
    `${wpEndPoint}wp-json/wp/v2/posts?filter[orderby]=date&order=desc&_embed&page=${currentPage}&per_page=8&categories=${selectedCategory}&v=${rand}`
  ));

export const useLibrary = (refetch) => {
  return useQuery(
    "library",
    async () => {
      const WP_RESPONCE_VALUE_KEY = 0;
      const response = await getLibrary();

      // Mapping Library
      const library = {
        title: response.data.metas.the_library_title[WP_RESPONCE_VALUE_KEY],
        currentContent: {},
        tabs: [], // title, id,
      };

      for (let x = 0; x < response.data.metas.the_library_; x++) {
        library.tabs.push({
          title:
            response.data.metas[`the_library__${x}_title`][
              WP_RESPONCE_VALUE_KEY
            ],
          id: response.data.metas[`the_library__${x}_post_category`][
            WP_RESPONCE_VALUE_KEY
          ].match(/"(\d*)"/gi)
            .toString()
            .replace(/["']+/g, ""),
          count:
            response.data.metas[`the_library__${x}_title`][
              WP_RESPONCE_VALUE_KEY
            ] === "All"
              ? response.data.library.category_all_count
              : response.data.library[`category_${x}_count`],
        });
      }

      return library;
    },
    {
      staleTime: 600_000, // cache it for 60000seconds
      retry: 1,
      enabled: refetch,
    }
  );
};

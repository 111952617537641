import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import _ from "lodash";
import clsx from "clsx";
import AppContext from "../../components/Context/AppContext";
import { useSinglePage } from "../../services/get-singlePage";
import { useInfoPackMeta } from "../../services/get-info-pack-meta";
import GoBackButton from "../../components/GoBackBtn/GoBackBtn";
import styles from "./InfoPackPage.module.scss";
import {
  getCustomImageTitle,
  getSingleObjProp as getSingleObjectProperty,
} from "../../lib/utils";
import Loader from "../../components/Loader/Loader";
import arrow from "../../images/arrow-up-solid.svg";
import {
  infoPackConfig,
  infocPackCheckListConfig,
  infoPackAddedPostConfig,
} from "../../lib/config";
import Icon from "../../components/Icon/Icon";
import ContentCard from "../../components/ContentCard/ContentCard";
import ErrorMessage from "../../components/ErrorMessage/ErrorMessage";
import Footer from "../../sections/Footer/Footer";
import Pagination from "../../components/Pagination/Pagination";
import usePage from "../../components/Hooks/usePage";

const InfoPackPage = () => {
  const { contentId, appNavHandler, featuredMedia } = useContext(AppContext);
  const [allLoading, setAllLoading] = useState(false);
  const [postContentList, setPostContentList] = useState([]);
  const [isPostsContentLoaded, setIsPostsContentLoaded] = useState(false);
  const [fileSources, setFileSource] = useState([]);
  const response = useSinglePage(contentId);
  const [count, setCount] = useState(0);
  const { data, isLoading, isError } = response;
  const metas = useInfoPackMeta(response?.data?.slug, response?.data?.slug);

  const infoPackMetaData = metas.isSuccess
    ? getCustomImageTitle(metas, infoPackConfig)
    : [];

  const infoPackCheckList = metas.isSuccess
    ? getSingleObjectProperty(metas, infocPackCheckListConfig)
    : [];

  const wpEndPoint = process.env.REACT_APP_WP_API_URL;

  const infoPackAddedPost = metas.isSuccess
    ? getSingleObjectProperty(metas, infoPackAddedPostConfig)
    : [];

  useEffect(() => {
    const getImageSources = async () => {
      const fileSourceArray =
        infoPackMetaData.length <= 0
          ? []
          : await Promise.all(
              infoPackMetaData.map(async (item, index) => {
                const fileId =
                  item[
                    `info_pack_downloadables_${index}_info_pack_downloadable_file`
                  ];

                const getImageUrl = await axios.get(
                  `${wpEndPoint}wp/v2/media/${Number(fileId)}`
                );

                return getImageUrl.data.guid.rendered;
              })
            );

      setFileSource(fileSourceArray);
    };

    getImageSources();

    if (isLoading && fileSources.length === 0 && metas.isLoading) {
      setAllLoading(true);
    } else {
      setAllLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metas.isSuccess]);

  useEffect(() => {
    const getAddedPosts = async () => {
      let postsAdded;

      postsAdded =
        infoPackAddedPost.length <= 0
          ? []
          : await Promise.all(
              infoPackAddedPost.map(async (item, index) => {
                const postId = await item[
                  `info_pack_page_posts_${index}_info_pack_added_posts`
                ];

                let postContent;
                let featuredImage;

                try {
                  postContent = await axios.get(
                    `${wpEndPoint}wp/v2/posts/${Number(postId)}`
                  );

                  featuredImage = await axios.get(
                    `${wpEndPoint}wp/v2/media/${postContent?.data?.featured_media}`
                  );
                } catch (error) {
                  throw error;
                }

                return {
                  ...postContent.data,
                  featuredImage: featuredImage.data.source_url,
                };
              })
            );

      setPostContentList([...postContentList, ...postsAdded]);
      setIsPostsContentLoaded(true);
    };

    getAddedPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metas.isSuccess, infoPackAddedPost.length]);

  const numberOfPages =
    postContentList.length > 0 ? Math.ceil(postContentList.length / 8) : 0;

  const pagination = usePage(0, numberOfPages, 8, 1000);

  const { nextPage, prevPage, pageRange, subIndexStart, subIndexEnd } =
    pagination;

  const pagesArray = [];

  const subPagedData =
    postContentList.length > 0
      ? (postContentList || []).slice(subIndexStart, subIndexEnd)
      : [];

  for (let index = 0; index < numberOfPages; index++) {
    pagesArray.push(index);
  }

  const leftDisabled = count === pagesArray[0];
  const rightDisabled = count === pagesArray[pagesArray.length - 1];

  const setCountHandler = (type) => {
    if (type === "prev") {
      setCount((count) => {
        return count === pagesArray[0] ? count : count - 1;
      });
    } else if (type === "next") {
      setCount((count) => {
        return count === pagesArray.length - 1 ? count : count + 1;
      });
    }
  };

  return (
    <div>
      <div>
        {allLoading ? (
          <Loader isLoading={featuredMedia === ""} />
        ) : isError ? (
          <ErrorMessage error="Something Went Wrong..." />
        ) : (
          <div>
            <GoBackButton action={() => appNavHandler("home", null, "")} />
            <h2 className={styles.pageTitle}>{data?.title?.rendered}</h2>
            <div
              className={styles.wpContentContainer}
              dangerouslySetInnerHTML={{ __html: data?.content?.rendered }}
            />
            {infoPackCheckList.length === 1 &&
            _.isEmpty(infoPackCheckList[0]) ? null : (
              <h4 className={clsx(styles.featuredInfoHeader)}>
                What's Included In This Info Pack?
              </h4>
            )}
            <div className={styles.checkListWrapper}>
              {infoPackCheckList.length === 1 && _.isEmpty(infoPackCheckList[0])
                ? null
                : infoPackCheckList.map((item, index) => {
                    const label =
                      item[`info_pack_check_list_${index}_info_pack_includes`];

                    return (
                      <label
                        className={styles.labelWrapper}
                        key={`info-checklist-${index}`}
                      >
                        <input type="checkbox" value={label} />
                        <p className={styles.checkListLabel}>{label}</p>
                      </label>
                    );
                  })}
            </div>
          </div>
        )}
        <h4 className={styles.featuredInfoHeader}>Content</h4>
        <div className={styles.contentCardWrapper}>
          {isPostsContentLoaded && subPagedData.length > 0 ? (
            subPagedData.map((post, index) => {
              return (
                <ContentCard
                  post={post}
                  actionHandler={() =>
                    appNavHandler(post.type, post.id, post.featuredImage)
                  }
                  index={index}
                  key={`content-card-${index}`}
                />
              );
            })
          ) : (
            <Loader />
          )}
        </div>
        <div>
          <div className={styles.paginationWrapper}>
            {numberOfPages > 1 && (
              <Pagination
                prevPage={prevPage}
                nextPage={nextPage}
                pageRange={pageRange}
                leftDisabled={leftDisabled}
                rightDisabled={rightDisabled}
                current={count + 1}
                setCountHandler={setCountHandler}
              />
            )}
          </div>
        </div>
        {!allLoading && fileSources.length > 0 ? (
          <>
            <div>
              <h3 className={styles.downloadableTitle}>Downloadable</h3>
            </div>
            {infoPackMetaData.length === 1 && _.isEmpty(infoPackMetaData[0])
              ? null
              : infoPackMetaData.map((item, index) => {
                  const name =
                    item[
                      `info_pack_downloadables_${index}_info_pack_downloadable_name`
                    ];

                  return (
                    <div
                      className={styles.arrowNameWraper}
                      key={`downloadable-${index}`}
                    >
                      <a
                        href={fileSources[index]}
                        download
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <Icon source={arrow} rotate />
                      </a>
                      <a
                        href={fileSources[index]}
                        download
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <p className={styles.downloadName}>{name}</p>
                      </a>
                    </div>
                  );
                })}
          </>
        ) : null}
      </div>
      <Footer inBlogs />
    </div>
  );
};

export default InfoPackPage;

import React from "react";
import styles from "./PremiumCard.module.scss";

const PremiumCard = (props) => {
  const { title, imageSource, link, index, subHeader } = props;

  return (
    <div className={styles.premiumCardContainer}>
      <div className={styles.imageContainer}>
        <a href={link} target="_blank" rel="noopener noreferrer">
          <img
            src={imageSource}
            className={styles.premiumImage}
            alt={`premium-book-${index}`}
          />
        </a>
      </div>
      <p className={styles.freeBook}>{subHeader}</p>
      <h3 className={styles.subHeaderCard}>{title}</h3>
      <a href={link} target="_blank" rel="noopener noreferrer">
        <p className={styles.discoverMore}>{`Discover More >>`}</p>
      </a>
    </div>
  );
};

export default PremiumCard;

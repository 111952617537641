import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import styles from "./SearchBox.module.scss";
import searchIcon from "../../images/searchIcon.svg";
import AppContext from "../Context/AppContext";
import usePage from "../Hooks/usePage";
import ResultCards from "../ResultCards/ResultsCards";
import { useSearch } from "../../services/get-search";
import Pagination from "../Pagination/Pagination";
import Divider from "../../components/Divider/Divider";
import Loader from "../../components/Loader/Loader";

const SearchBox = () => {
  const { appNavHandler } = useContext(AppContext);
  const [enteredSearch, setEnteredSearch] = useState("");
  const [count, setCount] = useState(0);
  const [clickSearch, setClickSearch] = useState(false);
  const [featuredImages, setFeaturedImages] = useState([]);
  const wpEndPoint = process.env.REACT_APP_WP_API_URL;
  const allResults = useSearch(enteredSearch, true);

  useEffect(() => {
    const getFeaturedImages = async () => {
      const featuredImagesArray = allResults.isSuccess
        ? await Promise.all(
            allResults.data.map(async (item, index) => {
              const getImageUrl = await axios.get(
                `${wpEndPoint}wp/v2/media/${item.featured_media}`
              );

              return getImageUrl.data.source_url;
            })
          )
        : [];

      setFeaturedImages([...featuredImages, ...featuredImagesArray]);
    };

    getFeaturedImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allResults.isSuccess]);

  const numberOfPages = allResults.isSuccess
    ? Math.ceil(allResults.data.length / 4)
    : 0;

  const pagination = usePage(0, numberOfPages, 4, 1000);

  const { nextPage, prevPage, pageRange, subIndexStart, subIndexEnd } =
    pagination;

  const pagesArray = [];

  const subPagedData = allResults.data
    ? (allResults.data || []).slice(subIndexStart, subIndexEnd)
    : [];

  for (let index = 0; index < numberOfPages; index++) {
    pagesArray.push(index);
  }

  const enteredValueHandler = (event) => {
    setEnteredSearch(event.target.value);
  };

  const setCountHandler = (type) => {
    if (type === "prev") {
      setCount((count) => {
        return count === pagesArray[0] ? count : count - 1;
      });
    } else if (type === "next") {
      setCount((count) => {
        return count === pagesArray.length - 1 ? count : count + 1;
      });
    }
  };

  const focusSearchHandler = () => {
    setClickSearch(false);
    setCount(0);
  };

  const searchResults =
    allResults.isSuccess && featuredImages.length > 0 ? (
      subPagedData.map((content, index) => {
        return (
          <ResultCards
            content={content}
            index={index}
            appNavHandler={appNavHandler}
            type={content?.type}
            id={content?.id}
            featuredImage={featuredImages[index]}
          />
        );
      })
    ) : (
      <div />
    );

  const leftDisabled = count === pagesArray[0];
  const rightDisabled = count === pagesArray[pagesArray.length - 1];

  return (
    <>
      <div className={styles.searchBoxWrapper}>
        <div className={styles.inputWrapper}>
          <input
            placeholder="Search for answers (i.e. how to fill in net income..)?"
            className={styles.circularInput}
            onChange={(event) => enteredValueHandler(event)}
            onFocus={focusSearchHandler}
          />
        </div>
        <div>
          <img
            src={searchIcon}
            alt="search-icon"
            className={styles.magnifyingGlass}
            onClick={() => setClickSearch(true)}
          />
        </div>
      </div>
      {clickSearch ? (
        <div className={styles.searchResultContainer}>
          {allResults.isLoading ? (
            <div className={styles.loaderContainer}>
              <Loader isLoading={allResults.isLoading} />
            </div>
          ) : (
            <div>
              <div className={styles.searchResultTitle}>Search Result:</div>
              <Divider />
              <div>
                {searchResults.length > 0 ? (
                  searchResults
                ) : (
                  <div>
                    <p className={styles.strongMessage}>
                      <strong>
                        {" "}
                        Sorry, we can't find anything related to that topic.{" "}
                      </strong>
                    </p>
                    <p className={styles.noResultMessage}>
                      Try rephrasing your search words or you can also browse
                      all our blogs and contents below. Alternatively, if you're
                      looking for something specific, you can also reach out to
                      our Helpdesk and we'd be happy to help.{" "}
                      <a
                        className={styles.helpdeskSearchLink}
                        href="https://wealthpathlabs.atlassian.net/servicedesk/customer/portal/2"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        Click here to go to Helpdesk.
                      </a>
                    </p>
                  </div>
                )}
              </div>
              {numberOfPages > 1 && (
                <Pagination
                  prevPage={prevPage}
                  nextPage={nextPage}
                  pageRange={pageRange}
                  leftDisabled={leftDisabled}
                  rightDisabled={rightDisabled}
                  current={count + 1}
                  setCountHandler={setCountHandler}
                />
              )}
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};

export default SearchBox;

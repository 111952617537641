import React from "react";
import styles from "./MoreBtn.module.scss";

const MoreButton = (props) => {
  const { actionHandler } = props;

  return (
    <p onClick={actionHandler} className={styles.moreButton}>
      {`More >>`}
    </p>
  );
};

export default MoreButton;
